import { AxiosResponse } from 'axios';

import $api from '@/base/api';

import { VideoCourse } from './models/VideoCourse';

export default class VideoService {
  getVideoCourse = async (id: number): Promise<AxiosResponse<VideoCourse>> =>
    $api.get(`/api/desktop/v1/course/${id}`);

  setWatchedTime = async (courseId: number, videoId: number, time: number) => {
    $api.post(`/api/desktop/v1/course/${courseId}/video/${videoId}/progress`, { time });
  };
}
