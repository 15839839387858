import { User, UserModel } from '../../user/models/UserModel';

export interface VideoCourse {
  created_at: string;
  created_by: UserModel;
  description: string;
  id: number;
  name: string;
  questions: [];
  videos: VideoDescription[];
  title?: string;
}

export const VideoCourseDto: VideoCourse = {
  created_at: '',
  created_by: User,
  description: '',
  id: 0,
  name: '',
  questions: [],
  videos: [],
  title: '',
};

interface VideoDescription {
  id: number;
  name: string;
  duration: number;
  progress: number;
  is_watched: boolean;
  preview: string;
  url: string;
}
