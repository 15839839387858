
interface TestImagesProps {
  images: string[];
}

const TestImages = ({ images }: TestImagesProps) => (
    <div className="testCardImages">
      {images.map((image: string, idx) => (
        <img
          src={`${image}`}
          alt="Изображение для вопроса"
          style={{ width: `calc(100% / ${images.length})` }}
          key={idx}
        />
      ))}
    </div>
);

export default TestImages;
