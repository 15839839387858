export const LoginForm: ILoginForm = {
  phone: '',
  code: '',
};

export interface ILoginForm {
  phone: string;
  code: string;
}

export interface LoginUserForm {
  username: string;
  password: string;
}

export enum LoginFields {
  PHONE = 'phone',
  CODE = 'code',
}
