import { Typography } from 'antd';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useEffectOnce } from 'react-use';

import { useRootStore } from '@/base/hooks/useRootStore';

import QLoader from '../../components/QLoader';
import FinishModal from './components/FinishModal';
import TestCard from './components/TestCard';

const TestScreen = observer(() => {
  const { id } = useParams();
  const { testStore } = useRootStore();

  // TODO: убрать после тестирования на проде
  // Effects
  // useEffect(() => {
  //   // if (testStore.courseInfo.is_started) {
  //   //   testStore.startAttempt(Number(id));
  //   // }
  //
  //   testStore.getCourseInfo(Number(id)).then(() => {
  //     if (testStore.courseInfo.is_started === false) {
  //       testStore.startAttempt(Number(id)).then(() => {
  //         testStore.getCourseInfo(Number(id));
  //       });
  //     }
  //   });
  //
  //   // return () => {
  //   //   testStore.resetStore();
  //   // };
  // }, [id]);

  // Effects
  useEffectOnce(() => {
    testStore.getCourseInfo(Number(id)).then(() => {
      if (testStore.courseInfo.is_started === false) {
        testStore.startAttempt(Number(id)).then(() => {
          testStore.getCourseInfo(Number(id));
        });
      }
    });
  });

  useEffect(
    () => () => {
      testStore.resetStore();
    },
    [id, testStore],
  );

  return (
    <div className="testScreen">
      <Typography.Title level={1}>{testStore.courseInfo.title}</Typography.Title>
      {testStore.courseInfo.questions?.length ? (
        <TestCard courseId={id} />
      ) : (
        <QLoader spinning={testStore.loading} />
      )}
      <FinishModal id={id} title={testStore.courseInfo.title} />
    </div>
  );
});

export default TestScreen;
