import { Modal, Typography } from 'antd';

import './styles.less';

import AdminButton from '@/admin/components/AdminButton';

const TEXT_CONST = {
  exit: 'Выйти',
  stay: 'Остаться',
  title: 'Выйти из редактирования',
};

interface AdminConfirmProps {
  isOpened: boolean;
  onCancel: () => void;
  onSubmit: () => void;
  modalText: string;
}

const AdminConfirmPopup = ({ isOpened, onCancel, onSubmit, modalText }: AdminConfirmProps) => {
  const Footer = () => (
    <div className="confirmModalFooter">
      <AdminButton height={40} onClick={onSubmit}>
        {TEXT_CONST.exit}
      </AdminButton>
      <AdminButton type="primary" onClick={onCancel}>
        {TEXT_CONST.stay}
      </AdminButton>
    </div>
  );
  return (
    <Modal
      centered
      open={isOpened}
      onCancel={onCancel}
      title={<Typography>{TEXT_CONST.title}</Typography>}
      footer={<Footer />}
      className="confirmModal"
    >
      <Typography className="confirmModalText">{modalText}</Typography>
    </Modal>
  );
};

export default AdminConfirmPopup;
