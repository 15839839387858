export const PhoneFormState: PhoneFormProps = {
  phone: '',
  code: '',
};

export interface PhoneFormProps {
  phone: string;
  code: string;
}

export enum PhoneFormFields {
  PHONE = 'phone',
  CODE = 'code',
}
