import { observer } from 'mobx-react-lite';
import { JSX, lazy, useEffect } from 'react';
import { createBrowserRouter, Navigate, useNavigate } from 'react-router-dom';

import AdminEditScreen from '@/admin/screens/AdminEditScreen';
import { useRootStore } from '@/base/hooks/useRootStore';
import FinishedVideoScreen from '@/screens/FinishedVideoScreen/FinishedVideoScreen';

import AdminLayout from '../admin/components/AdminLayout';
// import AdminCourseScreen from "../admin/screens/AdminCourseScreen";
import AdminCreateCourseScreen from '../admin/screens/AdminCreateCourseScreen';
// import AdminMainScreen from "../admin/screens/AdminMainScreen";
// import AdminTestScreen from "../admin/screens/AdminTestScreen";
import Layout from '../components/Layout';
import AuthScreen from '../screens/AuthScreen';
// import AvailableScreen from "../screens/AvailableScreen";
// import FinishedScreen from "../screens/FinishedScreen";
// import MainScreen from "../screens/MainScreen";
import TestScreen from '../screens/TestScreen';
import VideoScreen from '../screens/VideoScreen';
import { AdminPath, RoutePath } from './router.constants';

// Screens
// const AuthScreen = lazy(() => import('../screens/AuthScreen'));
const MainScreen = lazy(() => import('../screens/MainScreen'));
const AvailableScreen = lazy(() => import('../screens/AvailableScreen'));
const FinishedScreen = lazy(() => import('../screens/FinishedScreen'));
// const TestScreen = lazy(() => import('../screens/TestScreen'));
// const VideoScreen = lazy(() => import('../screens/VideoScreen'));

// Admin Screens
const AdminMainScreen = lazy(() => import('../admin/screens/AdminMainScreen'));
const AdminCourseScreen = lazy(() => import('../admin/screens/AdminCourseScreen'));
// const AdminCreateCourseScreen = lazy(() => import('../admin/screens/AdminCreateCourseScreen'));
const AdminTestScreen = lazy(() => import('../admin/screens/AdminTestScreen'));
const AdminTestsResults = lazy(() => import('../admin/screens/AdminTestsResults'));

interface PrivateRouteProps {
  children: JSX.Element;
}

const PrivateRoute = observer(({ children }: PrivateRouteProps) => {
  const { authStore } = useRootStore();
  const isAuth = authStore.checkAuth();
  const navigate = useNavigate();
  // const isAdmin = localStorage.getItem('is_admin')
  // Effects
  useEffect(() => {
    if (!isAuth) {
      navigate(RoutePath.auth);
    }
  }, [isAuth, navigate]);

  // useMount(() => {
  //   if (isAuth &&isAdmin === 'false') {
  //     navigate(RoutePath.root);
  //   }
  // })

  return authStore.isAuth ? children : <Navigate to={RoutePath.auth} />;
});

export const router = createBrowserRouter([
  {
    element: <Layout />,
    children: [
      {
        path: RoutePath.auth,
        element: <AuthScreen />,
      },
      {
        path: RoutePath.root,
        element: (
          <PrivateRoute>
            <MainScreen />
          </PrivateRoute>
        ),
      },
      {
        path: RoutePath.available,
        element: (
          <PrivateRoute>
            <AvailableScreen />
          </PrivateRoute>
        ),
      },
      {
        path: RoutePath.finished,
        element: (
          <PrivateRoute>
            <FinishedScreen />
          </PrivateRoute>
        ),
      },
      {
        path: RoutePath.test,
        element: (
          <PrivateRoute>
            <TestScreen />
          </PrivateRoute>
        ),
      },
      {
        path: '*',
        element: <Navigate to={RoutePath.root} />,
      },
    ],
  },
  {
    element: (
      <PrivateRoute>
        <VideoScreen />
      </PrivateRoute>
    ),
    path: RoutePath.video,
  },
  {
    element: (
      <PrivateRoute>
        <FinishedVideoScreen />
      </PrivateRoute>
    ),
    path: RoutePath.finished_video,
  },
  {
    element: <AdminLayout />,
    children: [
      {
        path: AdminPath.root,
        element: (
          <PrivateRoute>
            <AdminMainScreen />
          </PrivateRoute>
        ),
      },
      {
        path: AdminPath.courses,
        element: (
          <PrivateRoute>
            <AdminCourseScreen />
          </PrivateRoute>
        ),
      },
      {
        path: AdminPath.tests,
        element: (
          <PrivateRoute>
            <AdminTestScreen />
          </PrivateRoute>
        ),
      },
      {
        path: AdminPath.test_results,
        element: (
          <PrivateRoute>
            <AdminTestsResults />
          </PrivateRoute>
        ),
      },
      {
        path: AdminPath.create_course,
        element: (
          <PrivateRoute>
            <AdminCreateCourseScreen />
          </PrivateRoute>
        ),
      },
      {
        path: AdminPath.edit_course,
        element: (
          <PrivateRoute>
            <AdminEditScreen />
          </PrivateRoute>
        ),
      },
    ],
  },
]);
