// eslint-disable-next-line @typescript-eslint/naming-convention
export enum APP_ROUTES {
  ROOT = 'root',
  AUTH = 'auth',
  AVAILABLE = 'available',
  FINISHED = 'finished',
  TEST = 'test',
  VIDEO = 'video',
  FINISHED_VIDEO = 'finished_video',
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum ADMIN_ROUTES {
  ROOT = 'root',
  COURSE = 'courses',
  CREATE_COURSE = 'create_course',
  TEST = 'tests',
  TESTS_RESULTS = 'test_results',
  EDIT_COURSE = 'edit_course',
}
