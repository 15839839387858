import { Form, InputNumber, Select } from 'antd';
import { observer } from 'mobx-react-lite';

import { useRootStore } from '@/base/hooks/useRootStore';

const TEXT_CONST = {
  score: 'Проходной балл',
  placeholder: 'Введите мин. кол-во баллов для прохождения',
  date: 'Срок прохождения',
};

const CoursePoints = observer(() => {
  const { adminStore } = useRootStore();

  return (
    <Form className="coursePoints">
      <Form.Item
        label={TEXT_CONST.score}
        name="score"
        rules={[{ required: true, message: '123' }]}
        validateStatus={adminStore?.errorMessage?.length > 1 ? 'error' : ''}
        help={adminStore.errorMessage}
        initialValue={adminStore.minScore === 0 ? null : adminStore.minScore}
      >
        <InputNumber
          placeholder={TEXT_CONST.placeholder}
          className="coursePointsInput"
          value={adminStore.minScore}
          onChange={(value) => adminStore.setMinScore(value)}
          controls={false}
          size="large"
        />
      </Form.Item>
      <Form.Item name="date" label={TEXT_CONST.date} rules={[{ required: true }]}>
        <Select
          defaultValue={adminStore.durationDate}
          onChange={adminStore.setDurationDate}
          size="large"
          options={[
            { value: 30, label: '30 дней' },
            { value: 0, label: 'Без срока' },
          ]}
          popupClassName="selectList"
        />
      </Form.Item>
    </Form>
  );
});

export default CoursePoints;
