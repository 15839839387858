import { Dropdown, MenuProps, Typography } from 'antd';
import { ReactComponent as PencilIcon } from 'assets/pencil-icon.svg';
import { ReactComponent as EmptyIcon } from 'assets/picture-icon.svg';
import { ReactComponent as ThreeDotsIcon } from 'assets/three-dots-icon.svg';
import { ReactComponent as TrashIcon } from 'assets/trash-icon.svg';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';

import AdminDeletePopup from '@/admin/components/AdminDeletePopup';
import AdminEditVideoPopup from '@/admin/components/AdminEditVideoPopup/AdminEditVideoPopup';
import { useRootStore } from '@/base/hooks/useRootStore';
import { Video } from '@/modules/common/models';

import './styles.less';

const TEXT_CONST =
  'Вы собираетесь удалить видеоурок. Удаление производится без возможности восстановления. Вы уверены, что хотите продолжить удаление?';

const AdminVideoCard = observer(({ name, id, preview }: Video) => {
  const { adminStore } = useRootStore();
  const { courseTitle, courseId, isCourseEdit } = adminStore;
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [isEditPopupOpened, setIsEditPopupOpened] = useState<boolean>(false);

  const items: MenuProps['items'] = [
    {
      label: (
        <div className="adminVideoCardMenu" onClick={() => setIsEditPopupOpened(true)}>
          <PencilIcon width={16} height={16} color="#4E9AD4" path="#4E9AD4" />
          Переименовать
        </div>
      ),
      key: 0,
    },
    {
      label: (
        <div className="adminVideoCardMenu -trash" onClick={() => setIsOpened(true)}>
          <TrashIcon width={16} height={16} /> Удалить
        </div>
      ),
      key: 1,
    },
  ];

  // Handlers
  const handleChangeTitle = (title: string) => {
    adminStore
      .changeVideoTitle(courseId, id, title)
      .then(() => setIsEditPopupOpened(false))
      .then(() => adminStore.getVideos(courseId));
  };

  return (
    <>
      <div className="adminVideoCard">
        <div className="adminVideoCardImageWrapper">
          {preview ? (
            <img src={`${preview}`} className="adminVideoCardImage" alt="Изображение курса" />
          ) : (
            <EmptyIcon />
          )}
        </div>
        <div className="adminVideoCardWrapper">
          <Typography className="adminVideoCardName">{name}</Typography>
          <div className="adminVideoCardBottom">
            <div className="adminVideoCardTitle">
              <span>{courseTitle}</span>
            </div>
            {isCourseEdit && (
              <Dropdown menu={{ items }} trigger={['click']}>
                <div className="adminVideoCardTrash">
                  <ThreeDotsIcon />
                </div>
              </Dropdown>
            )}
          </div>
        </div>
      </div>
      <AdminDeletePopup
        isOpened={isOpened}
        onCancel={() => setIsOpened(false)}
        onSubmit={() => adminStore.deleteVideo(courseId, id)}
        modalText={TEXT_CONST}
        loading={adminStore.loading}
      />
      <AdminEditVideoPopup
        title={name}
        isOpened={isEditPopupOpened}
        onCancel={() => setIsEditPopupOpened(false)}
        onSubmit={(value) => handleChangeTitle(value)}
        loading={adminStore.loading}
      />
    </>
  );
});

export default AdminVideoCard;
