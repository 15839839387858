import { Checkbox, Radio, Typography } from 'antd';
import lodash from 'lodash';

import { Answer } from '@/modules/common/models';

interface TestAnswerProps {
  handleCheck: (answerId: number[]) => void;
  multiple: boolean;
  activeVariant: number[];
  answers: Answer[];
  isDisabled: (id: number) => boolean;
}

const TestAnswer = ({ answers, multiple, handleCheck, isDisabled }: TestAnswerProps) => {
  const options = lodash.map(answers, (item) => ({
    label: item.name,
    value: item.id,
    disabled: isDisabled(item.id),
  }));

  return (
    <>
      {multiple ? (
        <Checkbox.Group
          options={options}
          onChange={(e) => handleCheck(e as number[])}
          className="testAnswerCheck"
        />
      ) : (
        <Radio.Group onChange={(e) => handleCheck([e.target.value])}>
          <div className="testAnswerWrapper">
            {lodash.map(answers, (item) => (
              <Radio key={item.id} value={item.id}>
                <Typography className="testAnswerText">{item.name}</Typography>
              </Radio>
            ))}
          </div>
        </Radio.Group>
      )}
    </>
  );
};

export default TestAnswer;
