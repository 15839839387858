import { Suspense } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useMount } from 'react-use';

import QLoader from '@/components/QLoader';

import AdminHeader from '../AdminHeader/AdminHeader';
import AdminSidebar from '../AdminSidebar/AdminSidebar';

import './styles.less';

const AdminLayout = () => {
  const isAdmin = JSON.parse(String(localStorage.getItem('is_admin')));
  const isCurator = JSON.parse(String(localStorage.getItem('is_curator')));
  const navigate = useNavigate();

  useMount(() => {
    if (!(isAdmin || isCurator)) {
      navigate('/');
    }
  });

  return (
    <>
      <AdminHeader />
      <div className="adminLayout">
        <AdminSidebar />
        <Suspense fallback={<QLoader spinning={true} />}>
          <Outlet />
        </Suspense>
      </div>
    </>
  );
};

export default AdminLayout;
