import { Input, Typography } from 'antd';
import { MaskedInput } from 'antd-mask-input';
import { MaskedInputProps } from 'antd-mask-input/build/main/lib/MaskedInput';
import { InputProps as AntdInputProps } from 'antd/lib/input';
import clsx from 'clsx';

import { ReactComponent as ErrorIcon } from '@/assets/error-icon.svg';
import { ReactComponent as HideIcon } from '@/assets/hideIcon.svg';
import { ReactComponent as ShowIcon } from '@/assets/showIcon.svg';
import './styles.less';

interface QInputProps extends AntdInputProps {
  placeholder?: string;
  error?: boolean;
  message?: string;
}

const QInput = ({ value, error, message, className, ...rest }: QInputProps) => (
  <>
    <div className={clsx('input', className)}>
      <Input value={value} {...rest} />
    </div>
    {error && (
      <div className="errorWrapper">
        <ErrorIcon />
        <Typography className="errorInputMessage">{message}</Typography>
      </div>
    )}
  </>
);

interface QInputMaskProps extends MaskedInputProps {
  error: boolean;
  message: string;
}

export const QInputMask = ({ value, error, message, onChange, ...rest }: QInputMaskProps) => (
  <div className="input">
    <MaskedInput {...rest} name="phone" value={value} onChange={onChange} className={error ? 'errorInput' : ''} />
    {error && (
      <div className="errorWrapper">
        <ErrorIcon />
        <Typography className="errorInputMessage">{message}</Typography>
      </div>
    )}
  </div>
);

export const QPasswordInput = ({ ...rest }: QInputProps) => (
  <div className="input">
    <Input.Password
      {...rest}
      iconRender={(visible) => (visible ? <ShowIcon style={{ cursor: 'pointer' }} /> : <HideIcon />)}
    />
  </div>
);

export default QInput;
