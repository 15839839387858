import { Typography } from 'antd';
import { ReactComponent as LogoutIcon } from 'assets/logout-icon.svg';
import { observer } from 'mobx-react-lite';

import { useRootStore } from '@/base/hooks/useRootStore';

interface LogoutButtonProps {
  name: string;
}

const LogoutButton = observer(({ name }: LogoutButtonProps) => {
  const { authStore } = useRootStore();

  return (
    <div className="logoutButton" data-test-id="header-logout-button">
      <Typography className="logoutButtonName">{name}</Typography>
      <LogoutIcon style={{ cursor: 'pointer' }} onClick={authStore.logout} />
    </div>
  );
});

export default LogoutButton;
