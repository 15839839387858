import { AxiosResponse } from 'axios';

import $api from '@/base/api';

import { ILoginForm, LoginUserForm } from './ILoginForm';
import { Auth, AuthCode } from './models/Auth';

export default class AuthService {
  getCode = async (phone: string): Promise<AxiosResponse<AuthCode>> => $api.put('/api/desktop/v1/auth', { phone });

  login = async (values: ILoginForm): Promise<AxiosResponse<Auth>> => $api.post('/api/desktop/v1/auth', values);

  loginByUser = async (values: LoginUserForm): Promise<AxiosResponse<Auth>> => $api.post('/api/desktop/v1/auth/login', values);

  logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('is_admin')
  };
}
