import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

import QButton from '@/components/QButton';
import { formatSeconds } from '@/helpers/helpers';

import { TEXT_CONST } from '../AuthScreen.types';

interface IResendButton {
  expireTime?: string;
  sentTime?: string;
  onClick: () => void;
}

// TODO: добавить счетчик от времени после правки часового пояса на бэке
const ResendButton = ({ onClick, expireTime, sentTime }: IResendButton) => {
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [remainingTime, setRemainingTime] = useState<number>(60);

  // Effects
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const targetTime = dayjs(expireTime);
    const currentTime = dayjs(sentTime);
    const differenceInSeconds = targetTime.diff(currentTime, 'second');

    setRemainingTime(differenceInSeconds);

    if (differenceInSeconds <= 0) {
      setIsDisabled(false);
    } else {
      const timer = setInterval(() => {
        setRemainingTime((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(timer);
            setIsDisabled(false);
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);

      return () => {
        clearInterval(timer);
      };
    }
  }, [expireTime, sentTime]);

  // Handlers
  const handleClick = () => {
    onClick();
    setIsDisabled(true);
  };

  return (
    <div>
      <QButton type="default" onClick={handleClick} disabled={isDisabled} className="resendButton">
        {TEXT_CONST.REPEAT}
        {' '}
        {isDisabled && formatSeconds(remainingTime)}
      </QButton>
    </div>
  );
};

export default ResendButton;
