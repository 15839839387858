import clsx from 'clsx';
import { Link, LinkProps } from 'react-router-dom';

interface QLinkProps extends LinkProps {
  disabled?: boolean;
}

const QLink = ({
  to, children, disabled, className,
}: QLinkProps) => (
    <Link to={to} className={clsx('qLink', disabled && '-disabled', className)}>
      {children}
    </Link>
);

export default QLink;
