import { makeAutoObservable } from 'mobx';

import ErrorStore from '@/modules/error/ErrorStore';

import { Course, CourseDto, FinishedCourse, FinishedDto } from '../common/models/Course';
import TestService from './TestService';

export default class TestStore {
  loading = false;

  errorMessage = '';

  openModal = false;

  activeQuestion = 0;

  // Отображает номер вопроса с сервера
  currentQuestion = 0;

  courseInfo: Course = CourseDto;

  finishResult: FinishedCourse = FinishedDto;

  private testService: TestService;

  constructor(private errorStore: ErrorStore) {
    makeAutoObservable(this);
    this.testService = new TestService();
  }

  getCourseInfo = (id: number) => {
    this.setLoading(true);
    return this.testService
      .getCourseInfo(id)
      .then(({ data }) => {
        this.setCourseInfo(data);
        this.setCurrentQuestion(data.current_question);
      })
      .catch((error) => this.setErrorMessage(error.response?.data?.errors[0]))
      .finally(() => this.setLoading(false));
  };

  startAttempt = (id: number) => {
    this.setLoading(true);
    return this.testService.startAttempt(id).finally(() => this.setLoading(false));
  };

  answerQuestion = (id: number, answer: number[]) => {
    this.setLoading(true);
    this.testService
      .answerQuestion(id, answer)
      .then(({ data }) => {
        this.setFinishResults(data);
        // this.errorStore.getErrorToast('');
        if (this.currentQuestion === this.courseInfo.question_count) return;
        this.nextActiveQuestion();
      })
      .catch((error) => this.errorStore.getErrorToast(error.response?.data?.errors[0]))
      .finally(() => this.setLoading(false));
  };

  getResults = (id: number) => {
    this.setLoading(true);
    this.testService
      .getResults(id)
      .then(({ data }) => {
        this.setFinishResults(data);
      })
      .catch((error) =>
        this.setErrorMessage(
          error?.response?.data?.description || error?.response?.data?.errors[0],
        ),
      )
      .finally(() => this.setLoading(false));
  };

  // Setters
  setLoading = (state: boolean) => {
    this.loading = state;
  };

  setErrorMessage = (error: string) => {
    this.errorMessage = error;
  };

  setCourseInfo = (info: Course) => {
    this.courseInfo = info;
  };

  setCurrentQuestion = (state: number) => {
    this.currentQuestion = state;
  };

  nextActiveQuestion = () => {
    this.activeQuestion += 1;
    this.currentQuestion += 1;
  };

  previousActiveQuestion = () => {
    if (this.activeQuestion > 0) {
      this.activeQuestion -= 1;
    }
  };

  setOpenModal = (value: boolean) => {
    this.openModal = value;
  };

  setFinishResults = (data: FinishedCourse) => {
    this.finishResult = data;
  };

  resetStore = () => {
    this.setCourseInfo(CourseDto);
    this.activeQuestion = 0;
    this.currentQuestion = 0;
  };
}
