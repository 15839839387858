import './styles.less';
import clsx from 'clsx';

interface Props {
  options: string[];
  value: string;
  onChange: (option: string) => void;
}

const BtnSwitch = (props: Props) => {
  const { options, value, onChange } = props;

  return (
    <div className="btnSwitchWrapper">
      {options.map((option, idx) => (
        <div
          data-test-id={`auth-button-option-${idx}`}
          key={idx}
          onClick={() => onChange(option)}
          className={clsx('btnSwitchButton', option === value && '-selected')}
        >
          <span>{option}</span>
        </div>
      ))}
    </div>
  );
};

export default BtnSwitch;
