import './styles.less';
import { useLocation } from 'react-router-dom';

import SidebarItem from './components/SidebarItem';

interface TextConst {
  name: string;
  path: string;
  icon: 'home' | 'hat' | 'notebook';
}

// Отключена вкладка с тестами на данный момент
const TEXT_CONST: TextConst[] = [
  { name: 'Главная', path: '/admin', icon: 'home' },
  { name: 'Курсы', path: '/admin/courses', icon: 'hat' },
  // { name: 'Тесты', path: '/admin/tests', icon: 'notebook' },
  { name: 'Результаты тестов', path: '/admin/tests-result', icon: 'notebook' },
];

const AdminSidebar = () => {
  const { pathname } = useLocation();

  return (
    <div className="adminSidebar">
      {TEXT_CONST.map((item) => (
        <SidebarItem
          key={item.name}
          iconName={item.icon}
          name={item.name}
          path={item.path}
          active={pathname === item.path}
        />
      ))}
    </div>
  );
};

export default AdminSidebar;
