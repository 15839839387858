import { Typography } from 'antd';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';

import { useRootStore } from '@/base/hooks/useRootStore';
import { Question } from '@/modules/common/models';

import QButton from '../../../components/QButton/QButton';
import QCard from '../../../components/QCard/QCard';
import TestAnswer from './TestAnswer';
import TestImages from './TestImages';

interface TestCardProps {
  questions?: Question[];
  next?: () => void;
  previous?: () => void;
  activeQuestion?: number;
  courseId: string | undefined;
  answerQuestion?: (id: number, answer: number[]) => void;
  questionCount?: number;
  currentQuestion?: number;
}

const TestCard = observer(({ courseId }: TestCardProps) => {
  const { testStore } = useRootStore();
  const { courseInfo, loading } = testStore;
  const [variant, setVariant] = useState<number[]>([]);
  const question = courseInfo.questions[testStore.activeQuestion];
  const isMultiple = question?.is_multiple;

  // Handlers
  const handleCheckAnswer = (answer: number[]) => {
    setVariant(answer);
  };

  const isDisabled = (id: number) =>
    variant.length === question.correct_count && variant.indexOf(id) === -1;

  const handleNextQuestion = async () => {
    if (testStore.currentQuestion === courseInfo.question_count) {
      await testStore.answerQuestion(Number(courseId), variant);
      await testStore.setOpenModal(true);
    } else {
      testStore.answerQuestion(Number(courseId), variant);
      setVariant([]);
    }
  };

  return (
    <QCard width={1155}>
      <div className="testCardWrapper">
        <Typography className="testCardCounter">
          {`Вопрос ${testStore.currentQuestion}/${courseInfo.question_count}`}
        </Typography>
        <Typography className="testCardQuestion">{question?.name}</Typography>

        {Boolean(question?.images?.length) && <TestImages images={question?.images} />}
        {isMultiple && (
          <Typography className="testCardMultiple">Выберите правильные варианты</Typography>
        )}
        <div className="testCardAnswers">
          <TestAnswer
            activeVariant={variant}
            answers={question?.answers}
            multiple={isMultiple}
            handleCheck={handleCheckAnswer}
            isDisabled={isDisabled}
          />
        </div>
        <div style={{ display: 'flex', gap: '13px' }}>
          <QButton
            loading={loading}
            width={347}
            onClick={handleNextQuestion}
            disabled={!variant.length}
          >
            Ответить
          </QButton>
        </div>
      </div>
    </QCard>
  );
});

export default TestCard;
