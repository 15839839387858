import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useRootStore } from '@/base/hooks/useRootStore';
import QLoader from '@/components/QLoader';
import { VideoCourseDto } from '@/modules/video/models/VideoCourse';

import VideoPlayer from './components/VideoPlayer';
import './styles.less';

const VideoScreen = observer(() => {
  const navigate = useNavigate();

  const { id } = useParams();
  const { videoStore } = useRootStore();

  const playlist = videoStore.loading
    ? []
    : videoStore.videoCourse.videos.map((item) => ({
        id: item.id,
        name: item.name,
        sources: [
          {
            src: item.url,
            type: 'video/webm',
          },
        ],
        poster: item.preview,
        duration: item.duration,
        progress: item.progress,
        isWatched: item.is_watched,
      }));

  const isLoading = videoStore.loading || videoStore.videoCourse.videos.length < 1;

  useEffect(() => {
    videoStore.getVideos(Number(id));
  }, [id, videoStore]);

  return isLoading ? (
    <QLoader spinning={videoStore.loading} />
  ) : (
    <VideoPlayer
      playlistName={videoStore.videoCourse.title || 'Плейлист курса'}
      playlist={playlist}
      autoPlay={false}
      onEnded={videoStore.setVideoWatched.bind(videoStore)}
      onTimeUpdate={videoStore.setWatchedTime.bind(videoStore)}
      onClose={() => {
        navigate(-1);
        videoStore.setVideoCourse(VideoCourseDto);
      }}
      onPassTestClick={() => {
        navigate(`/test/${videoStore.videoCourse.id}`);
      }}
    />
  );
});

export default VideoScreen;
