// eslint-disable-next-line @typescript-eslint/naming-convention
export enum TEXT_CONST {
  HEADING = 'Вход на платформу обучения сотрудников клуба',
  LOGIN = 'Логин',
  PASSWORD = 'Пароль',
  PHONE = 'Телефон',
  ENTER = 'Войти',
  CODE = 'Введите код из смс',
  REPEAT = 'Отправить повторно',
  NEXT = 'Далее',
  BACK = 'Назад',
}
