import { Typography } from 'antd';
import { useState } from 'react';

import AdminButton from '@/admin/components/AdminButton';
import QCard from '@/components/QCard/QCard';
import { Question } from '@/modules/common/models';
import TestAnswer from '@/screens/TestScreen/components/TestAnswer';
import TestImages from '@/screens/TestScreen/components/TestImages';

import './styles.less';

interface AdminTestCardProps extends Question {
  onSubmit: (correctAnswer: number[]) => void;
  activeStep: number;
  totalSteps: number;
}

const AdminTestCard = ({
  name,
  images,
  answers,
  is_multiple,
  onSubmit,
  activeStep,
  totalSteps,
  correct_count,
}: AdminTestCardProps) => {
  const [variant, setVariant] = useState<number[]>([]);

  // Handlers
  const handleCheckAnswer = (answer: number[]) => {
    setVariant(answer);
  };

  const handleNextQuestion = () => {
    onSubmit(variant);
    setVariant([]);
  };

  const isDisabled = (id: number) => variant.length === correct_count && variant.indexOf(id) === -1;

  return (
    <QCard width={1155} className="adminTestCard">
      <div>
        <Typography className="adminTestCardCounter">{`Вопрос ${activeStep}/${totalSteps}`}</Typography>
        <Typography className="adminTestCardQuestion">{name}</Typography>
        <div>{Boolean(images?.length) && <TestImages images={images} />}</div>
        {is_multiple && (
          <Typography className="testCardMultiple">Выберите правильные варианты</Typography>
        )}
        <div className="adminTestCardAnswers">
          <TestAnswer
            activeVariant={variant}
            answers={answers}
            multiple={is_multiple}
            handleCheck={handleCheckAnswer}
            isDisabled={isDisabled}
          />
        </div>
        <div>
          <AdminButton
            height={56}
            width={347}
            onClick={handleNextQuestion}
            disabled={!variant.length}
            type="primary"
          >
            Ответить
          </AdminButton>
        </div>
      </div>
    </QCard>
  );
};

export default AdminTestCard;
