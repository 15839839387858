import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';

import { useRootStore } from '@/base/hooks/useRootStore';
import QButton from '@/components/QButton';
import QInput, { QPasswordInput } from '@/components/QInput';
import { LoginUserForm } from '@/modules/auth/ILoginForm';

import { TEXT_CONST } from '../AuthScreen.types';

const LoginForm = observer(() => {
  const [loginData, setLoginData] = useState<LoginUserForm>({
    username: '',
    password: '',
  });
  const { authStore } = useRootStore();

  const handleSubmit = () => {
    authStore.loginByUser(loginData);
  };

  return (
    <>
      <div className="formWrapper">
        <QInput
          name="username"
          placeholder={TEXT_CONST.LOGIN}
          autoFocus
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setLoginData({
            ...loginData,
            username: e.target.value,
          })}
          error={Boolean(authStore.errorMessage)}
          message={authStore.errorMessage}
        />
        <QPasswordInput
          name="password"
          placeholder={TEXT_CONST.PASSWORD}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setLoginData({
            ...loginData,
            password: e.target.value,
          })}
        />
      </div>
      <QButton disabled={!loginData.username || !loginData.password} onClick={handleSubmit}>
        {TEXT_CONST.ENTER}
      </QButton>
    </>
  );
});

export default LoginForm;
