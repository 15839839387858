/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Input, Typography, Upload, UploadProps, Progress } from 'antd';
import type { UploadFile } from 'antd/es/upload/interface';
import { ReactComponent as VideoIcon } from 'assets/video-icon.svg';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';

import $api from '@/base/api';
import { useRootStore } from '@/base/hooks/useRootStore';

import AdminButton from '../../../components/AdminButton/AdminButton';

const { Dragger } = Upload;

const TEXT_CONST = {
  name: 'Название:',
  namePlaceholder: 'Дайте уроку название',
  video: 'Видео:',
  drag: 'Нажмите или перетащите файл в эту область, чтобы загрузить',
  recommendation:
    "Рекомендуем файл не больше 5 гб + форматы видео ('webm', 'mpg', 'mp2', 'mpeg', 'mpe', 'mpv', 'ogg', 'mp4', 'm4p', 'm4v', 'avi', 'wmv', 'mov', 'qt', 'flv', 'swf', 'avchd')",
  back: 'Отменить',
  upload: 'Загрузить',
  progress: 'Прогресс:',
  error: 'Ошибка. Файл не соответствует требованиям',
};

interface UploadForm {
  name: string;
  file: UploadFile | null;
}

const MAX_SIZE = 5368709120; // 5гб в байтах

const VideoAddScreen = observer(() => {
  const [formState, setFormState] = useState<UploadForm>({
    name: '',
    file: null,
  });
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const { adminStore } = useRootStore();

  const fileValidation = () => {
    if (!formState.file?.size) return false;
    return formState.file.size > MAX_SIZE;
  };

  const uploadChunks = async () => {
    if (!formState.file?.size) return;
    setUploading(true);
    const uniqueDate = dayjs().toString();

    const chunkSize = 100 * 1024 * 1024; // 2MB
    const totalChunks = Math.ceil(formState.file.size / chunkSize);
    let offset = 0;

    for (let chunkIndex = 0; chunkIndex < totalChunks; chunkIndex++) {
      // @ts-ignore
      const chunk = formState.file.slice(offset, offset + chunkSize);
      offset += chunkSize;

      const formData = new FormData();

      formData.append('file', chunk);
      // @ts-ignore
      formData.append('chunkIndex', chunkIndex + 1);
      // @ts-ignore
      formData.append('totalChunks', totalChunks);
      formData.append('name', formState.name);
      formData.append('identifier', uniqueDate);

      try {
        // eslint-disable-next-line no-await-in-loop
        await $api
          .post(`/api/desktop/v1/course/${adminStore.courseId}/video/chunk`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            onUploadProgress: (progressEvent) => {
              // @ts-ignore
              const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
              const totalProgress =
                totalChunks === 1 ? progress : Math.round(((chunkIndex + 1) / totalChunks) * 100);
              setUploadProgress(totalProgress);
            },
          })
          .then((res) => {
            if (res.status === 201) {
              adminStore.setVideoUpload(false);
              adminStore.getVideos(adminStore.courseId);
            }
          });
      } catch (error) {
        // Убрать после того как решим куда выводить ошибку
        // Добавить обработку если чанк не загрузился, то отправить повторно
        // eslint-disable-next-line no-console
        console.log(error);
      }
    }
    setUploadProgress(100);
  };

  // Отключено пока не появится стриминговая загрузка
  // const handleUploadVideo = () => {
  //   // adminStore.uploadFile(adminStore.courseId, formState);
  // };

  const handleChange: UploadProps['onChange'] = ({ file }) => {
    setFormState({ ...formState, file });
  };

  return (
    <div className="videoAddScreen">
      <div className="videoAddScreenRow">
        <Typography className="videoAddScreenText">{TEXT_CONST.name}</Typography>
        <Input
          placeholder={TEXT_CONST.namePlaceholder}
          name="name"
          className="videoAddScreenInput"
          onChange={(event) =>
            setFormState({
              ...formState,
              [event.target.name]: event.target.value,
            })
          }
        />
      </div>
      <div className="videoAddScreenRow">
        <Typography className="videoAddScreenText">{TEXT_CONST.video}</Typography>
        <div>
          <Dragger
            multiple={false}
            maxCount={1}
            name="file"
            beforeUpload={() => false}
            accept="video/mp4"
            onChange={handleChange}
          >
            <VideoIcon />
            <Typography className="videoAddScreenDrag">{TEXT_CONST.drag}</Typography>
          </Dragger>
          {fileValidation() && (
            <Typography className="videoAddScreenUploadError">{TEXT_CONST.error}</Typography>
          )}
          <Typography className="videoAddScreenUploadText">{TEXT_CONST.recommendation}</Typography>
        </div>
      </div>
      {uploading && (
        <div className="videoAddScreenRow">
          <Typography>{TEXT_CONST.progress}</Typography>
          <Progress percent={uploadProgress} />
        </div>
      )}
      <div className="videoAddScreenRow">
        <div />
        <div className="videoAddScreenButtons">
          <AdminButton
            width={131}
            height={48}
            onClick={() => adminStore.setVideoUpload(false)}
            disabled={Boolean(!adminStore.uploadedVideos.length)}
          >
            {TEXT_CONST.back}
          </AdminButton>
          <AdminButton
            loading={uploading}
            width={130}
            height={48}
            type="primary"
            onClick={uploadChunks}
            disabled={!formState.name || !formState.file || fileValidation()}
          >
            {TEXT_CONST.upload}
          </AdminButton>
        </div>
      </div>
    </div>
  );
});

export default VideoAddScreen;
