import type { MenuProps } from 'antd';
import { Button, Checkbox, Dropdown, Radio, Typography, Upload } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { UploadChangeParam } from 'antd/es/upload/interface';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { ReactComponent as AnswerAddIcon } from 'assets/answer-add-icon.svg';
import { ReactComponent as CancelIcon } from 'assets/cancel-icon.svg';
import { ReactComponent as CheckedIcon } from 'assets/checked-icon.svg';
import { ReactComponent as DownIcon } from 'assets/down-icon.svg';
import { ReactComponent as ImageIcon } from 'assets/picture-icon.svg';
import { ReactComponent as SingleIcon } from 'assets/single-icon.svg';
import { ReactComponent as TrashIcon } from 'assets/trash-icon.svg';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';

import AdminButton from '@/admin/components/AdminButton/AdminButton';
import { appConfig } from '@/appConfig';
import { useRootStore } from '@/base/hooks/useRootStore';
import { QuestionCardForm, questionCardState } from '@/modules/admin/AdminInterface';

import CourseButtons from './components/CourseButtons';
import CoursePoints from './components/CoursePoints';
import VisibilityPopup from './components/VisabilityPopup';
import { getPreparedForm, getPreparedObj, getTrueIndexes, validateQuestions } from './helpers';

import './styles.less';

const TEXT_CONST = {
  question: 'Введите текст вопроса',
  answer: 'Ответ',
  answer_1: 'Ответ 1',
  answer_2: 'Ответ 2',
  single: 'Один из списка',
  multiple: 'Несколько из списка',
  upload: 'Нажмите или перетащите файл в эту область, чтобы загрузить',
  previous: 'Предыдущий шаг',
  create: 'Создать курс',
  save: 'Сохранить',
  cancel: 'Отменить',
};

const AdminQuestionCard = observer(() => {
  const { adminStore } = useRootStore();
  const [questionForm, setQuestionForm] = useState<QuestionCardForm[]>(() => {
    if (adminStore.courseInfo.questions.length === 0) {
      return [questionCardState];
    }
    return getPreparedForm(adminStore.courseInfo.questions);
  });

  const items: MenuProps['items'] = [
    {
      label: TEXT_CONST.single,
      key: '0',
      icon: <SingleIcon />,
    },
    {
      label: TEXT_CONST.multiple,
      key: '1',
      icon: <CheckedIcon />,
    },
  ];

  const onCancel = () => {
    adminStore.setIsCourseEdit();
    setQuestionForm(getPreparedForm(adminStore.courseInfo.questions));
  };

  const onChange = (files: UploadChangeParam, activeQuestionIndex: number) => {
    const updatedCards = [...questionForm];
    updatedCards[activeQuestionIndex].files = files.fileList;

    setQuestionForm(updatedCards);
  };
  // eslint-disable-next-line
  const handleMenuClick = (event: any, index: number) => {
    const updatedCards = [...questionForm];
    updatedCards[index] = { ...updatedCards[index], isMultiple: Boolean(Number(event.key)) };

    setQuestionForm(updatedCards);
  };

  const handleAddAnswer = (activeQuestionIndex: number) => {
    const updatedCards = [...questionForm];
    updatedCards[activeQuestionIndex].answers.push({ name: '', correct: false });

    setQuestionForm(updatedCards);
  };

  const handleChangeQuestion = (e: React.ChangeEvent<HTMLTextAreaElement>, index: number) => {
    const updatedCards = [...questionForm];
    updatedCards[index] = { ...updatedCards[index], question: e.target.value };
    setQuestionForm(updatedCards);
  };

  const handleAnswerChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
    index: number,
    activeQuestionIndex: number,
  ) => {
    const updatedCards = [...questionForm];
    const updatedAnswers = [...updatedCards[activeQuestionIndex].answers];

    updatedAnswers[index] = { ...updatedAnswers[index], name: event.target.value };
    updatedCards[activeQuestionIndex].answers = updatedAnswers;

    setQuestionForm(updatedCards);
  };

  const handleCorrectAnswer = (index: number, activeQuestionIndex: number) => {
    const updatedCards = [...questionForm];
    const updatedAnswers = [...updatedCards[activeQuestionIndex].answers];

    for (let i = 0; i < updatedAnswers.length; i++) {
      updatedAnswers[i].correct = i === index;
    }
    updatedCards[activeQuestionIndex].answers = updatedAnswers;
    updatedCards[activeQuestionIndex].checkedIndex = index;

    setQuestionForm(updatedCards);
  };

  const handleCorrectMultiAnswer = (values: CheckboxValueType[], activeQuestionIndex: number) => {
    const updatedCards = [...questionForm];
    const updatedAnswers = [...updatedCards[activeQuestionIndex].answers];
    const answers = updatedAnswers.map((answer, index) => {
      if (values.includes(index)) {
        return { ...answer, correct: true };
      }
      return { ...answer, correct: false };
    });
    updatedCards[activeQuestionIndex].answers = answers;
    setQuestionForm(updatedCards);
  };

  const handleDeleteQuestion = (activeQuestionIndex: number) => {
    setQuestionForm((prevState) =>
      prevState.filter((item, index) => index !== activeQuestionIndex),
    );
  };

  const handleSubmitAnswer = () => {
    setQuestionForm([
      ...questionForm,
      {
        question: '',
        answers: [
          { name: '', correct: false },
          { name: '', correct: false },
        ],
        isMultiple: false,
        files: undefined,
        checkedIndex: null,
        withPicture: false,
        images: [],
        hoveredIndex: -1,
      },
    ]);
  };

  const handleSubmitCourse = async () => {
    const preparedObj = getPreparedObj(questionForm);
    adminStore.setCreatedQuestions(preparedObj);
    adminStore.submitCourse();
  };

  const handleAddPicture = (activeQuestionIndex: number) => {
    const updatedCards = [...questionForm];
    updatedCards[activeQuestionIndex].withPicture = !updatedCards[activeQuestionIndex].withPicture;
    setQuestionForm(updatedCards);
  };

  const menuProps = {
    items,
    // eslint-disable-next-line
    onClick: (event: any, index: number) => handleMenuClick(event, index),
  };

  // Функция для удаления вновь созданного вопроса. Необходимость обсуждается
  const handleDeleteAnswer = (activeQuestionIndex: number, answerIndex: number) => {
    const updatedCards = [...questionForm];
    const updatedAnswers = [...updatedCards[activeQuestionIndex].answers];
    const answers = updatedAnswers.filter((item, index) => index !== answerIndex);
    updatedCards[activeQuestionIndex].answers = answers;
    setQuestionForm(updatedCards);
  };

  const setHoveredIndex = (activeQuestionIndex: number, answerIndex: number) => {
    const updatedCards = [...questionForm];
    updatedCards[activeQuestionIndex].hoveredIndex = answerIndex;
    setQuestionForm(updatedCards);
  };

  const removeHoveredIndex = (activeQuestionIndex: number) => {
    const updatedCards = [...questionForm];
    updatedCards[activeQuestionIndex].hoveredIndex = -1;
    setQuestionForm(updatedCards);
  };

  return (
    <>
      {questionForm.map((item, questionIndex) => {
        const isValid = validateQuestions(item.answers);

        return (
          <React.Fragment key={questionIndex}>
            <div
              className={clsx('adminQuestionCard', {
                '-notValid': Boolean(questionForm[questionIndex].question.length) && !isValid,
                '-disabled': !adminStore.isCourseEdit,
              })}
              key={questionIndex}
            >
              <TextArea
                showCount={adminStore.isCourseEdit}
                maxLength={255}
                placeholder={TEXT_CONST.question}
                bordered={false}
                style={{ height: 80, resize: 'none' }}
                value={item.question}
                onChange={(event) => handleChangeQuestion(event, questionIndex)}
                autoSize
              />
              {item.withPicture && (
                <div className="adminQuestionCardImages">
                  {item.images.map((image) => (
                    <img
                      src={`${appConfig.apiUrl}${image}`}
                      className="adminQuestionCardImage"
                      key={image}
                      alt={image}
                    />
                  ))}
                  <Upload
                    listType="picture-card"
                    fileList={questionForm[questionIndex].files}
                    accept="image/*"
                    multiple={true}
                    beforeUpload={() => false}
                    onChange={(files) => onChange(files, questionIndex)}
                  >
                    <ImageIcon width={28} height={28} />
                  </Upload>
                </div>
              )}
              {!questionForm[questionIndex].files?.length && adminStore.isCourseEdit && (
                <ImageIcon
                  width={20}
                  height={20}
                  className="adminQuestionCardImageBtn"
                  onClick={() => handleAddPicture(questionIndex)}
                />
              )}
              {questionForm[questionIndex].isMultiple ? (
                <Checkbox.Group
                  className="adminQuestionCardCheckbox"
                  onChange={(values) => handleCorrectMultiAnswer(values, questionIndex)}
                  defaultValue={getTrueIndexes(questionForm[questionIndex].answers)}
                >
                  {questionForm[questionIndex].answers.map((answer, index) => (
                    <div
                      className={`-wrapper ${item.hoveredIndex === index ? '-hovered' : ''}`}
                      key={index}
                      onMouseEnter={() => setHoveredIndex(questionIndex, index)}
                      onMouseLeave={() => removeHoveredIndex(questionIndex)}
                    >
                      <Checkbox key={index} value={index}>
                        <TextArea
                          autoSize
                          bordered={false}
                          placeholder={`${TEXT_CONST.answer} ${index + 1}`}
                          value={questionForm[questionIndex].answers[index].name}
                          onChange={(event) => handleAnswerChange(event, index, questionIndex)}
                        />
                      </Checkbox>
                      {item.hoveredIndex === index && (
                        <CancelIcon
                          className="-trash-icon"
                          onClick={() => handleDeleteAnswer(questionIndex, index)}
                        />
                      )}
                    </div>
                  ))}
                </Checkbox.Group>
              ) : (
                <Radio.Group
                  className="adminQuestionCardQuestions"
                  value={questionForm[questionIndex].checkedIndex}
                  onChange={(e) => handleCorrectAnswer(e.target.value, questionIndex)}
                >
                  {questionForm[questionIndex].answers.map((answer, index) => (
                    <div
                      className={`-wrapper ${item.hoveredIndex === index ? '-hovered' : ''}`}
                      key={index}
                      onMouseEnter={() => setHoveredIndex(questionIndex, index)}
                      onMouseLeave={() => removeHoveredIndex(questionIndex)}
                    >
                      <Radio value={index} key={index}>
                        <TextArea
                          placeholder={`${TEXT_CONST.answer} ${index + 1}`}
                          bordered={false}
                          value={questionForm[questionIndex].answers[index].name}
                          onChange={(event) => handleAnswerChange(event, index, questionIndex)}
                          autoSize
                          className="TEST"
                        />
                      </Radio>
                      {item.hoveredIndex === index && (
                        <CancelIcon
                          className="-trash-icon"
                          onClick={() => handleDeleteAnswer(questionIndex, index)}
                        />
                      )}
                    </div>
                  ))}
                </Radio.Group>
              )}
              {adminStore.isCourseEdit && (
                <div className="adminQuestionCardBtnWrapper">
                  <Dropdown
                    menu={{
                      items: menuProps.items,
                      onClick: (e) => handleMenuClick(e, questionIndex),
                    }}
                  >
                    <Button className="adminQuestionCardBtn">
                      <div className="adminQuestionCardType">
                        {questionForm[questionIndex].isMultiple ? (
                          <>
                            <CheckedIcon /> <Typography>{TEXT_CONST.multiple}</Typography>
                          </>
                        ) : (
                          <>
                            <SingleIcon /> <Typography>{TEXT_CONST.single}</Typography>
                          </>
                        )}
                      </div>
                      <DownIcon />
                    </Button>
                  </Dropdown>
                  <Button
                    className="adminQuestionCardBtn"
                    onClick={() => handleAddAnswer(questionIndex)}
                  >
                    <AnswerAddIcon /> {TEXT_CONST.answer}
                  </Button>
                  <Button
                    className="adminQuestionCardTrash"
                    onClick={() => handleDeleteQuestion(questionIndex)}
                  >
                    <TrashIcon />
                  </Button>
                </div>
              )}
            </div>
            {Boolean(questionForm[questionIndex].question.length) && !isValid && (
              <Typography className="adminQuestionCardError">
                {questionForm[questionIndex].isMultiple
                  ? 'Пожалуйста, выберите один или несколько правильных ответов'
                  : 'Пожалуйста, выберите один правильный ответ'}
              </Typography>
            )}
          </React.Fragment>
        );
      })}
      {adminStore.isCourseEdit && (
        <Button className="adminQuestionCardAddBtn" onClick={handleSubmitAnswer}>
          <AnswerAddIcon /> Добавить вопрос
        </Button>
      )}

      {adminStore.isCourseEdit && <CoursePoints />}
      {adminStore.isEditScreen ? (
        adminStore.isCourseEdit && (
          <CourseButtons
            onCancel={onCancel}
            disabled={!questionForm.length || Boolean(adminStore.minScore === 0)}
            onSave={handleSubmitCourse}
          />
        )
      ) : (
        <div className="adminQuestionCardSubmit">
          <AdminButton width={200} height={48} onClick={() => adminStore.previousActiveStep()}>
            {TEXT_CONST.previous}
          </AdminButton>
          <AdminButton
            type="primary"
            width={157}
            height={48}
            disabled={!questionForm.length || Boolean(adminStore.minScore === 0)}
            onClick={handleSubmitCourse}
          >
            {TEXT_CONST.create}
          </AdminButton>
        </div>
      )}

      <VisibilityPopup />
    </>
  );
});

export default AdminQuestionCard;
