import { LoadingOutlined } from '@ant-design/icons';
import { Modal, Typography, Spin } from 'antd';
import { observer } from 'mobx-react-lite';
import plural from 'plural-ru';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as NotPassedIcon } from '@/assets/not-passed-icon.svg';
import { ReactComponent as PassedIcon } from '@/assets/passed-icon.svg';
import { useRootStore } from '@/base/hooks/useRootStore';
import QButton from '@/components/QButton';

const TEXT_CONST = {
  passed: 'Поздравляем!',
  not_passed: 'Тест не пройден',
  button: 'Завершить тест',
};

const antIcon = <LoadingOutlined style={{ fontSize: 100 }} spin />;

interface FinishModalProps {
  id: string | undefined;
  title: string;
}

const FinishModal = observer(({ title }: FinishModalProps) => {
  const { testStore } = useRootStore();
  const navigate = useNavigate();

  // Handlers
  const handleSubmit = () => {
    testStore.setOpenModal(false);
    testStore.resetStore();
    if (testStore?.finishResult?.status?.code === 'failed') {
      navigate('/available');
    } else {
      navigate('/finished');
    }
  };

  const finishText =
    testStore?.finishResult?.status?.code === 'failed'
      ? `К сожалению, Вы набрали только ${testStore.finishResult.correct_question_count} ${plural(
          testStore.finishResult.score,
          'балл',
          'балла',
          'баллов',
        )} из ${
          testStore.finishResult.max
        } возможных по курсу “${title}”. Пройдите курс еще один раз и повторите тестирование`
      : `Вы набрали ${testStore.finishResult.correct_question_count} ${plural(
          testStore.finishResult.score,
          'балл',
          'балла',
          'баллов',
        )} из ${testStore.finishResult.max}
          возможных и завершили курс “${title}”. Уроки по нему теперь в разделе “Завершенные курсы”.`;

  return (
    <Modal
      open={testStore.openModal}
      width={580}
      centered
      footer={<QButton onClick={handleSubmit}>{TEXT_CONST.button}</QButton>}
      closeIcon={false}
      maskClosable={false}
      className="finishModal"
    >
      <div className="finishModalWrapper">
        {testStore.finishResult?.status?.name === undefined ? (
          <Spin indicator={antIcon} />
        ) : (
          <>
            {testStore?.finishResult?.status?.code === 'failed' ? (
              <NotPassedIcon />
            ) : (
              <PassedIcon />
            )}
            <Typography.Title level={2} className="finishModalHeading">
              {testStore?.finishResult?.status?.code === 'failed'
                ? TEXT_CONST.not_passed
                : TEXT_CONST.passed}
            </Typography.Title>
            <Typography className="finishModalText">{finishText}</Typography>
          </>
        )}
      </div>
    </Modal>
  );
});

export default FinishModal;
