import { ConfigProvider } from 'antd';
import ruRu from 'antd/es/locale/ru_RU';
import './App.less';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

import { router } from '@/router';

import themeJSON from './ant_design_theme.json';
import { useRootStore } from './base/hooks/useRootStore';

const App = observer(() => {
  const { authStore, userStore } = useRootStore();

  useEffect(() => {
    authStore.checkAuth();
  }, [authStore]);

  useEffect(() => {
    if (authStore.isAuth) {
      userStore.getUser();
    }
  }, [authStore.isAuth, userStore]);

  return (
    <ConfigProvider locale={ruRu} theme={themeJSON}>
      <ToastContainer position="top-right" />
      <RouterProvider router={router} />
    </ConfigProvider>
  );
});

export default App;
