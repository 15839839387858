import { RefObject, useEffect, useMemo, useState } from 'react';

import { ReactComponent as VolumeDown } from '@/assets/volume-down.svg';
import { ReactComponent as VolumeMute } from '@/assets/volume-mute.svg';
import { ReactComponent as VolumeUp } from '@/assets/volume-up.svg';
import QSlider from '@/components/QSlider';

import './styles.less';

interface VolumeProps {
  videoRef: RefObject<HTMLVideoElement>;
}

const WIDTH = 45;
const HEIGHT = 45;

const Volume = ({ videoRef }: VolumeProps) => {
  const [value, setValue] = useState(videoRef.current?.volume ?? 1);

  const IconComponent = useMemo(() => {
    if (value === 0) return VolumeMute;
    if (value < 0.5) return VolumeDown;

    return VolumeUp;
  }, [value]);

  const formatVolume = (currentValue: number | undefined) => {
    if (currentValue) return currentValue * 100;
    return currentValue;
  };

  useEffect(() => {
    if (videoRef.current?.volume !== undefined) videoRef.current.volume = value;
  }, [value, videoRef]);

  return (
    <div className="volumeControl">
      <IconComponent className="controlsPlayButton" width={WIDTH} height={HEIGHT} />
      <div className="volumeSliderContainer">
        <QSlider
          className="volumeSlider"
          value={value}
          onChange={setValue}
          max={1}
          step={0.1}
          tooltip={{ formatter: formatVolume }}
          railStyle={{ backgroundColor: 'rgba(255, 255, 255, 0.50)' }}
          trackStyle={{ backgroundColor: '#FFFFFF' }}
          vertical
        />
      </div>
    </div>
  );
};

export default Volume;
