import { Input, Radio, Typography } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import AdminButton from '@/admin/components/AdminButton';
import { useRootStore } from '@/base/hooks/useRootStore';
import QLoader from '@/components/QLoader';
import { CourseInfo } from '@/modules/common/models';

import './styles.less';

const TEXT_CONST = {
  name: 'Название:',
  section: 'Раздел:',
  access: 'Доступ:',
  all: 'Открыт всем',
  admin: 'Виден только администраторам',
  adminSpan: 'По умолчанию курс будет виден только администраторам',
  cancel: 'Отменить',
  save: 'Сохранить',
};

const InformationScreen = observer(() => {
  const { adminStore } = useRootStore();
  const { id } = useParams();
  const { title, description, is_active: isActive } = adminStore.courseInfo;
  const [formState, setFormState] = useState<CourseInfo>({
    name: '',
    description: '',
    is_active: false,
  });
  const isEdit = adminStore.isCourseEdit;

  // Effects
  useEffect(() => {
    setFormState({ name: title, description, is_active: isActive });
  }, [description, isActive, title]);

  // Handlers
  const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormState({ ...formState, [event.target.name]: event.target.value });
  };

  const resetFormState = () => {
    setFormState({ name: title, description, is_active: isActive });
  };

  // Render
  if (adminStore.loading) {
    return <QLoader spinning />;
  }

  return (
    <div className="editInformation">
      <div className="editInformationRow">
        <Typography className="editInformationText">{TEXT_CONST.name}</Typography>
        <Input
          value={formState.name}
          name="name"
          onChange={handleChangeInput}
          className="editInformationInput"
          bordered={isEdit}
          disabled={!isEdit}
        />
      </div>
      <div className="editInformationRow">
        <Typography className="editInformationText">{TEXT_CONST.section}</Typography>
        <Input
          value={formState.description}
          name="description"
          onChange={handleChangeInput}
          className="editInformationInput"
          bordered={isEdit}
          disabled={!isEdit}
        />
      </div>
      <div className="editInformationRow">
        <Typography className="editInformationText">{TEXT_CONST.access}</Typography>
        {isEdit ? (
          <div>
            <Radio.Group
              value={formState.is_active}
              onChange={(e) => setFormState({ ...formState, is_active: e.target.value })}
            >
              <Radio value={false}>{TEXT_CONST.admin}</Radio>
              <Radio value={true}>{TEXT_CONST.all}</Radio>
            </Radio.Group>
            <Typography className="editInformationSpan">{TEXT_CONST.adminSpan}</Typography>
          </div>
        ) : (
          <Typography className="editInformationInfo -edit">
            {isActive ? TEXT_CONST.all : TEXT_CONST.admin}
          </Typography>
        )}
      </div>
      {isEdit && (
        <div className="editInformationRow">
          <div></div>
          <div className="editInformationBtn">
            <AdminButton
              height={48}
              onClick={() => {
                adminStore.setIsCourseEdit();
                resetFormState();
              }}
            >
              {TEXT_CONST.cancel}
            </AdminButton>
            <AdminButton
              type="primary"
              height={48}
              onClick={() => adminStore.changeCourseInfo(Number(id), formState)}
              disabled={!formState.name || !formState.description}
            >
              {TEXT_CONST.save}
            </AdminButton>
          </div>
        </div>
      )}
    </div>
  );
});

export default InformationScreen;
