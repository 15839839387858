import { Button } from 'antd';
import { ButtonProps } from 'antd/lib';

interface QButtonProps extends ButtonProps {
  disabled?: boolean;
  type?: 'primary' | 'default';
  width?: number;
  height?: number;
}

const QButton = ({
  children, disabled, type = 'primary', width, height, ...rest
}: QButtonProps) => (
    <div className="qButton">
      <Button disabled={disabled} type={type} style={{ width: `${width}px`, height: `${height}px` }} {...rest}>
        {children}
      </Button>
    </div>
);

export default QButton;
