import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { SpinProps } from 'antd/lib';
import clsx from 'clsx';

import './styles.less';

const antIcon = <LoadingOutlined style={{ fontSize: 100 }} spin />;

const QLoader = ({ spinning, className }: SpinProps) => (
  <Spin
    className={clsx('spinner', className)}
    spinning={spinning}
    size="large"
    indicator={antIcon}
  />
);

export default QLoader;
