import { Button } from 'antd';
import { ButtonProps } from 'antd/lib';

import './styles.less';

interface AdminButtonProps extends ButtonProps {
  width?: number;
  height?: number;
}

const AdminButton = ({
  children, className, width, height, ...rest
}: AdminButtonProps) => (
    <div className="adminButton">
      <Button className={className} {...rest} style={{ width, height }}>
        {children}
      </Button>
    </div>
);

export default AdminButton;
