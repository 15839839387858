import { makeAutoObservable } from 'mobx';

import AuthService from './AuthService';
import { ILoginForm, LoginForm, LoginUserForm } from './ILoginForm';

export default class AuthStore {
  loading = false;

  errorMessage = '';

  isAuth = false;

  isAuthCheckComplete = false;

  isCodeReceived = false;

  newCodeTime = '';

  codeTimeSent = '';

  loginForm = LoginForm;

  private authService;

  constructor() {
    makeAutoObservable(this);

    this.authService = new AuthService();
  }

  checkAuth = () => {
    this.setIsAuthCheckComplete(true);
    const accessToken = localStorage.getItem('token');

    if (accessToken) {
      localStorage.setItem('token', accessToken);
      this.setIsAuth(true);
      this.setIsAuthCheckComplete(false);
      return true;
    }
    localStorage.setItem('is_admin', 'false');
    localStorage.setItem('is_curator', 'false');
    this.setIsAuth(false);
    this.setIsAuthCheckComplete(true);
    return false;
  };

  getCode = (phone: string) => {
    this.setLoading(true);
    this.authService
      .getCode(phone)
      .then((response) => {
        if (response.status === 200) this.setCodeReceived(true);
        this.setNewCodeTime(response.data?.new_code_generate);
        this.setCodeTimeSent(response.data?.code_sent);
        this.setErrorMessage('');
      })
      .catch((error) => this.setErrorMessage(error.response?.data?.errors[0]))
      .finally(() => this.setLoading(false));
  };

  login = (data: ILoginForm) => {
    this.setLoading(true);
    this.authService
      .login(data)
      .then((response) => {
        localStorage.setItem('token', response.data?.token);
        localStorage.setItem('user', JSON.stringify(response.data?.user));
        localStorage.setItem('is_admin', JSON.stringify(response?.data?.is_admin));
        localStorage.setItem('is_curator', JSON.stringify(response?.data?.is_curator));
        this.setIsAuth(true);
        this.setErrorMessage('');
      })
      .catch((error) => this.setErrorMessage(error.response.data.errors[0]))
      .finally(() => this.setLoading(false));
  };

  loginByUser = (data: LoginUserForm) => {
    this.setLoading(true);
    this.authService
      .loginByUser(data)
      .then((response) => {
        localStorage.setItem('token', response.data?.token);
        localStorage.setItem('user', JSON.stringify(response.data?.user));
        localStorage.setItem('is_admin', JSON.stringify(response?.data?.is_admin));
        localStorage.setItem('is_curator', JSON.stringify(response?.data?.is_curator));
        this.setIsAuth(true);
        this.setErrorMessage('');
      })
      .catch((error) => this.setErrorMessage(error.response.data.errors[0]))
      .finally(() => this.setLoading(false));
  };

  logout = () => {
    this.authService.logout();
    this.setIsAuth(false);
    this.setCodeReceived(false);
  };

  // Setters
  setLoading = (state: boolean) => {
    this.loading = state;
  };

  setIsAuth = (state: boolean) => {
    this.isAuth = state;
  };

  setErrorMessage = (message: string) => {
    this.errorMessage = message;
  };

  setCodeReceived = (state: boolean) => {
    this.isCodeReceived = state;
  };

  setIsAuthCheckComplete = (state: boolean) => {
    this.isAuthCheckComplete = state;
  };

  setNewCodeTime = (time: string) => {
    this.newCodeTime = time;
  };

  setCodeTimeSent = (time: string) => {
    this.codeTimeSent = time;
  };

  resetStore = () => {
    this.errorMessage = '';
    this.isCodeReceived = false;
    this.newCodeTime = '';
    this.codeTimeSent = '';
  };
}
