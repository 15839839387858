import { Answers, QuestionCardForm, QuestionsResponse } from '@/modules/admin/AdminInterface';

export const getPreparedObj = (questions: QuestionCardForm[]): QuestionCardForm[] =>
  questions.map((obj) => ({
    ...obj,
    answers: obj.answers.filter((item) => item.name.length >= 1),
  }));

const isMultiple = (answers: Answers[]) => {
  const correctAnswers = answers.filter((answer) => answer.correct);
  return correctAnswers.length > 1;
};

export const getTrueIndexes = (answers: Answers[]) => {
  const indexes: number[] = answers.reduce((acc: number[], item, index) => {
    if (item.correct) {
      acc.push(index);
    }
    return acc;
  }, []);
  return indexes;
};

export const getPreparedForm = (questionArr: QuestionsResponse[]): QuestionCardForm[] =>
  questionArr.map((question) => ({
    answers: question.answers.map((answer) => ({
      name: answer.name,
      correct: answer.correct,
    })),
    withPicture: Boolean(question.images.length),
    isMultiple: isMultiple(question.answers),
    files: undefined,
    checkedIndex: getTrueIndexes(question.answers)[0],
    question: question.name,
    images: question.images,
    hoveredIndex: -1,
  }));

export const validateQuestions = (arr: Answers[]) => arr.some((item) => item.correct);
