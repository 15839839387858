import clsx from 'clsx';
import { SyntheticEvent, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useKey, useUnmount } from 'react-use';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide, SwiperProps } from 'swiper/react';
import 'swiper/less';

import 'swiper/less/navigation';

import { ReactComponent as CloseIcon } from '@/assets/close.svg';
import QButton from '@/components/QButton';
import QLoader from '@/components/QLoader';
import QTypography from '@/components/QTypography';

import { VideoPlayerProps } from './VideoPlayer.types';
import Controls from './components/Controls';
import PlaylistItem from './components/PlaylistItem';
import './styles.less';

const HIDE_OVERLAY_AFTER = 2000;

const swiperSettings: SwiperProps = {
  // To remove this comment we need to update typescript to 5+ version,
  // but it conflicts with craco
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  modules: [Navigation],
  navigation: true,
  spaceBetween: 16,
  slidesOffsetBefore: 64,
  slidesOffsetAfter: 64,
  slidesPerView: 1.1,
  breakpoints: {
    400: {
      spaceBetween: 16,
      slidesPerView: 1.9,
    },
    620: {
      spaceBetween: 16,
      slidesPerView: 2.1,
    },
    820: {
      spaceBetween: 16,
      slidesPerView: 3.1,
    },
    1200: {
      spaceBetween: 16,
      slidesPerView: 4.1,
    },
    1400: {
      spaceBetween: 16,
      slidesPerView: 5.1,
    },
  },
};

const VideoPlayer = ({
  playlistName,
  playlist,
  initialIndex = 0,
  autoPlay,
  onEnded,
  onTimeUpdate,
  onPassTestClick,
  onClose,
}: VideoPlayerProps) => {
  const timer = useRef<number | null>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  // Need for triggering react update in case when a user
  // move mouse - overlay is showing
  // press Space button - video is pausing
  // but UI doesn't update because nothing changes in state
  const [, setOverlayTriggerSource] = useState<string | null>(null);

  const [timeProgress, setTimeProgress] = useState(0);
  const [showOverlay, setShowOverlay] = useState(false);
  const [ended, setEnded] = useState(false);
  const [inFullscreen, setInFullscreen] = useState(false);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(initialIndex);
  const [isLoading, setIsLoading] = useState(false);

  const { sources, poster, duration, isWatched, id } = playlist[currentVideoIndex];

  const isCourseFinished = playlist.every((video) => video.isWatched);

  const onVideoEnded = () => {
    onEnded?.(id);
    setEnded(true);
    setShowOverlay(true);
    setOverlayTriggerSource('VIDEO_ENDED');
  };

  const onVideoTimeUpdate = (event: SyntheticEvent<HTMLVideoElement, Event>) => {
    const time = Math.ceil(event.currentTarget.currentTime);

    if (time !== timeProgress) {
      onTimeUpdate?.(id, time);
      setTimeProgress(time);
    }
  };

  const handlePlay = (index?: number, forceStart?: boolean) => {
    if (index !== undefined && index !== currentVideoIndex) {
      setCurrentVideoIndex(index);
      videoRef.current?.load();
    }

    if (forceStart && videoRef.current) {
      videoRef.current.currentTime = 0;
    }

    videoRef.current?.play();
    setEnded(false);
    setShowOverlay(false);
  };

  const handlePause = (source: string) => {
    videoRef.current?.pause();
    setOverlayTriggerSource(source);
    setShowOverlay(true);
  };

  const handleMouseMove = () => {
    setShowOverlay(true);
    setOverlayTriggerSource('MOUSE_MOVE');

    if (videoRef.current?.paused) return;

    clearTimeout(timer.current!);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    timer.current = setTimeout(() => {
      if (videoRef.current?.paused) return;
      setShowOverlay(false);
    }, HIDE_OVERLAY_AFTER);
  };

  const handleProgressChange = (seconds: number) => {
    setTimeProgress(seconds);
    videoRef.current!.currentTime = seconds;
  };

  const handleNextClick = () => {
    if (currentVideoIndex !== playlist.length - 1) {
      handlePlay(currentVideoIndex + 1);
    }
  };

  const handlePrevClick = () => {
    if (currentVideoIndex !== 0) {
      handlePlay(currentVideoIndex - 1);
    }
  };

  const handleFullscreenClick = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen();
      setInFullscreen(false);
    } else {
      setInFullscreen(true);
      containerRef.current!.requestFullscreen();
    }
  };

  useKey(' ', (event) => {
    event.preventDefault();

    if (videoRef.current?.paused) {
      handlePlay();
    } else {
      handlePause('SPACE_BTN_PRESS');
    }
  });

  useUnmount(() => {
    if (timer.current) clearTimeout(timer.current);
    if (videoRef.current) videoRef.current.pause();
    setIsLoading(true);
  });

  // Включаем паузу при переключении на другую вкладку
  if (videoRef.current && videoRef.current?.currentTime > 0) {
    window.addEventListener('blur', () => {
      handlePause('BLUR');
    });
  }

  return (
    <div ref={containerRef} className="videoPlayerContainer" onMouseMove={handleMouseMove}>
      <QLoader spinning={isLoading} className="videoLoader" />
      <video
        ref={videoRef}
        onEnded={onVideoEnded}
        onTimeUpdate={onVideoTimeUpdate}
        poster={poster}
        autoPlay={autoPlay}
        controls={false}
        className="videoPlayer"
        onCanPlay={() => setIsLoading(false)}
        onWaiting={() => setIsLoading(true)}
        preload="none"
      >
        {sources.map(({ src, type }) => (
          <source key={type} src={src} type={type} />
        ))}
        Video not supported.
      </video>
      <div className={clsx(['overlayContainer', showOverlay && 'overlayContainer-shown'])}>
        <div className="overlayHeader">
          <QTypography.Title level={1} className="playlistName">
            {playlistName}
          </QTypography.Title>
          <CloseIcon className="closeButton" onClick={onClose} />
        </div>
        <div className="overlayBody">
          <Swiper {...swiperSettings}>
            {playlist.map((playlistItem, index) => {
              const showPlayButton =
                index === currentVideoIndex && Boolean(videoRef.current?.paused) && !ended;
              const showReplayButton =
                index === currentVideoIndex && Boolean(videoRef.current?.paused) && ended;

              return (
                <SwiperSlide key={playlistItem.id}>
                  <PlaylistItem
                    item={playlistItem}
                    onPlayClick={() => handlePlay(index)}
                    onReplayClick={() => handlePlay(index, true)}
                    showPlayButton={showPlayButton}
                    showReplayButton={showReplayButton}
                  />
                </SwiperSlide>
              );
            })}
          </Swiper>
          {ended && (
            <div className="videoEndedButtonGroup">
              <QButton
                className="oneMoreTimeButton"
                onClick={isCourseFinished ? () => navigate('/') : () => handlePlay()}
              >
                {isCourseFinished ? 'Вернуться на главную' : 'Просмотреть еще раз'}
              </QButton>
              <QButton
                className="nextVideoButton"
                onClick={isCourseFinished ? onPassTestClick : handleNextClick}
              >
                {isCourseFinished ? 'Пройти тест' : 'Следующее видео'}
              </QButton>
            </div>
          )}
        </div>
        <div className="overlayFooter">
          <Controls
            videoRef={videoRef}
            ended={ended}
            isWatched={isWatched}
            progress={timeProgress}
            duration={duration}
            inFullscreen={inFullscreen}
            disableNext={currentVideoIndex === playlist.length - 1}
            disablePrev={currentVideoIndex === 0}
            onProgressChange={handleProgressChange}
            onPlayClick={() => handlePlay()}
            onReplayClick={() => handlePlay()}
            onPrevClick={handlePrevClick}
            onNextClick={handleNextClick}
            onPauseClick={() => handlePause('ICON_BUTTON')}
            onFullscreenClick={handleFullscreenClick}
          />
        </div>
      </div>
    </div>
  );
};

export default VideoPlayer;
