import './styles.less';
import { Typography } from 'antd';
import { ReactComponent as AddIcon } from 'assets/plus-icon.svg';
import { observer } from 'mobx-react-lite';

import { useRootStore } from '@/base/hooks/useRootStore';

const TEXT_CONST = 'Добавить урок';

const AdminVideoAddCard = observer(() => {
  const { adminStore } = useRootStore();
  return (
    <div className="videoAddCard" onClick={() => adminStore.setVideoUpload(true)}>
      <AddIcon />
      <Typography className="videoAddCardText">{TEXT_CONST}</Typography>
    </div>
  );
});

export default AdminVideoAddCard;
