import { Modal, Typography } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useState } from 'react';

import './styles.less';

import AdminButton from '@/admin/components/AdminButton';

interface AdminEditVideoProps {
  title: string;
  isOpened: boolean;
  onCancel: () => void;
  onSubmit: (value: string) => void;
  loading: boolean;
}

const TEXT_CONST = {
  title: 'Переименовать курс',
  cancel: 'Отменить',
  submit: 'Сохранить',
};

const AdminEditVideoPopup = ({
  title,
  isOpened,
  onCancel,
  onSubmit,
  loading,
}: AdminEditVideoProps) => {
  const [videoTitle, setVideoTitle] = useState<string>(title);

  const Footer = () => (
    <div className="deleteModalFooter">
      <AdminButton height={40} onClick={onCancel}>
        {TEXT_CONST.cancel}
      </AdminButton>
      <AdminButton
        type="primary"
        onClick={() => onSubmit(videoTitle)}
        loading={loading}
        disabled={!videoTitle}
      >
        {TEXT_CONST.submit}
      </AdminButton>
    </div>
  );
  return (
    <Modal
      open={isOpened}
      centered
      title={<Typography>{TEXT_CONST.title}</Typography>}
      footer={<Footer />}
      width={384}
      onCancel={onCancel}
    >
      <div className="adminEditVideoWrapper">
        <TextArea autoSize value={videoTitle} onChange={(e) => setVideoTitle(e.target.value)} />
      </div>
    </Modal>
  );
};

export default AdminEditVideoPopup;
