import { makeAutoObservable } from 'mobx';

import UserService from './UserService';
import { Courses, CoursesModel, User, UserModel } from './models/UserModel';

export default class UserStore {
  userInfo: UserModel = User;

  userCourseCount: CoursesModel = Courses;

  errorMessage = '';

  isAdmin = false;

  isCurator = false;

  private userService;

  constructor() {
    makeAutoObservable(this);

    this.userService = new UserService();
  }

  getUser = () => {
    this.userService
      .getUser()
      .then(({ data }) => {
        this.setUserInfo(data.user);
        this.setUserCourseCount(data.courses);
        this.setIsAdmin(data.is_admin);
        this.setIsCurator(data.is_curator);
      })
      .catch((error) => {
        this.setErrorMessage(
          error?.response?.data?.description || error?.response?.data?.errors[0],
        );
      });
  };

  // Setters
  private setUserInfo = (userInfo: UserModel) => {
    this.userInfo = userInfo;
  };

  private setUserCourseCount = (courseCount: CoursesModel) => {
    this.userCourseCount = courseCount;
  };

  setErrorMessage = (message: string) => {
    this.errorMessage = message;
  };

  setIsAdmin = (state: boolean) => {
    this.isAdmin = state;
  };

  setIsCurator = (state: boolean) => {
    this.isCurator = state;
  };
}
