import { useRef } from 'react';
import { useHoverDirty } from 'react-use';

import { ReactComponent as PlayButton } from '@/assets/play-button.svg';
import { ReactComponent as ReplayButton } from '@/assets/replay-button.svg';
import QButton from '@/components/QButton';
import QTypography from '@/components/QTypography';

import { PlaylistItem as PlaylistItemInterface } from '../../VideoPlayer.types';

import './styles.less';

const SEC_IN_MIN = 60;

interface PlaylistItemProps {
  showPlayButton: boolean;
  showReplayButton: boolean;
  item: PlaylistItemInterface;
  onPlayClick(): void;
  onReplayClick(): void;
}

const PlaylistItem = ({
  item,
  onReplayClick,
  onPlayClick,
}: // showPlayButton,
// showReplayButton,
PlaylistItemProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const isHovered = useHoverDirty(ref);

  const { name, sources, poster, progress, duration, isWatched } = item;

  const durationLabel =
    duration > SEC_IN_MIN
      ? `${Math.floor(duration / SEC_IN_MIN)} мин`
      : `${Math.floor(duration)} сек`;

  // const showPlay = (isHovered && !item.isWatched) || showPlayButton;
  // const showReplay = (isHovered && item.isWatched && !showPlayButton) || showReplayButton;
  const showPlay = isHovered && !item.isWatched;
  const showReplay = isHovered && item.isWatched;

  return (
    <div ref={ref} className="playlistItem">
      <div className="playlistItemPosterContainer">
        <div className="playlistItemLabel playlistItemWatchStateContainer">
          <QTypography.Text className="playlistItemLabelText">
            {isWatched ? 'Просмотрено' : 'Не просмотрено'}
          </QTypography.Text>
        </div>
        <img src={poster || sources[0].src} alt={`${name} poster`} className="playlistItemPoster" />
        <div className="playlistItemAction">
          {showPlay && (
            <QButton
              shape="circle"
              type="primary"
              onClick={onPlayClick}
              icon={<PlayButton width={40} height={40} />}
            />
          )}
          {showReplay && (
            <QButton
              shape="circle"
              type="primary"
              onClick={onReplayClick}
              icon={<ReplayButton width={24} height={30} />}
            />
          )}
        </div>
        <div className="playlistItemMeta">
          <div className="playlistItemLabel playlistItemDurationContainer">
            <QTypography.Text className="playlistItemLabelText">{durationLabel}</QTypography.Text>
          </div>
          <div className="playlistItemProgressBar">
            <div className="playlistItemProgressBar-progress" style={{ width: `${progress}%` }} />
          </div>
        </div>
      </div>
      <QTypography.Text className="playlistItemName">{name}</QTypography.Text>
    </div>
  );
};

export default PlaylistItem;
