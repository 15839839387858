import { Steps, Typography } from 'antd';
import { ReactComponent as BackArrow } from 'assets/arrow-left.svg';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';

import { useRootStore } from '@/base/hooks/useRootStore';
import { AdminPath } from '@/router';

import './styles.less';

const stepItems = [
  {
    title: 'Заполните информацию',
  },
  {
    title: 'Добавьте видеоуроки',
  },
  {
    title: 'Добавьте тестирование',
  },
];

const TEXT_CONST = 'Создание курса';

const CreateSteps = observer(() => {
  const navigate = useNavigate();
  const { adminStore } = useRootStore();

  const handleBack = () => {
    adminStore.resetStore();
    navigate(AdminPath.courses);
  };
  return (
    <div className="createSteps">
      <div className="createStepsWrapper">
        {/* <QLink onClick={adminStore.resetStore} to={AdminPath.courses}> */}
        <BackArrow onClick={handleBack} className="createStepsBack" />
        {/* </QLink> */}
        <Typography className="createStepsHeading">{TEXT_CONST}</Typography>
      </div>
      <div className="createStepsContainer">
        <Steps current={adminStore.activeStep} items={stepItems} />
      </div>
    </div>
  );
});

export default CreateSteps;
