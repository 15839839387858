import { Typography } from 'antd';
import clsx from 'clsx';

import QLink from '../../QLink/QLink';

interface CourseCounterProps {
  title: string;
  counter: number;
  active: boolean;
  path: string;
  dataTestId?: string;
  disabled: boolean;
}

const CourseCounter = ({
  title, counter, active, path, dataTestId, disabled,
}: CourseCounterProps) => (
    <QLink to={path} disabled={disabled}>
      <div className={clsx('courseCounter', active && '-active')} data-test-id={`header-screen-${dataTestId}`}>
        <Typography className="courseCounterText">{title}</Typography>
        <div className={clsx('courseCounterCount', counter === 0 && '-empty')}>{counter}</div>
      </div>
    </QLink>
);

export default CourseCounter;
