import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';

import AdminButton from '@/admin/components/AdminButton/AdminButton';
import AdminVideoAddCard from '@/admin/components/AdminVideoAddCard/AdminVideoAddCard';
import AdminVideoCard from '@/admin/components/AdminVideoCard/AdminVideoCard';
import { useRootStore } from '@/base/hooks/useRootStore';

const TEXT_CONST = {
  previous: 'Предыдущий шаг',
  next: 'Следующий шаг',
};

const VideosScreen = observer(() => {
  const { adminStore } = useRootStore();

  useEffect(() => {
    adminStore.getVideos(adminStore.courseId);
  }, [adminStore, adminStore.courseId]);

  return (
    <div className="videosScreen">
      <div className="videosScreenWrapper">
        {adminStore.uploadedVideos.map((item) => (
          <AdminVideoCard key={item.id} {...item} />
        ))}
        <AdminVideoAddCard />
      </div>
      <div className="videosScreenButtons">
        <AdminButton width={200} height={48}>
          {TEXT_CONST.previous}
        </AdminButton>
        <AdminButton
          disabled={Boolean(!adminStore.uploadedVideos.length)}
          type="primary"
          width={186}
          height={48}
          onClick={() => adminStore.nextActiveStep()}
        >
          {TEXT_CONST.next}
        </AdminButton>
      </div>
    </div>
  );
});

export default VideosScreen;
