import AdminButton from '@/admin/components/AdminButton';

const TEXT_CONST = {
  save: 'Сохранить',
  cancel: 'Отменить',
};

interface CourseButtonsProps {
  onCancel: () => void;
  onSave: () => void;
  disabled: boolean;
}

const CourseButtons = ({ onSave, onCancel, disabled }: CourseButtonsProps) => (
  <div className="adminQuestionCardSubmit">
    <AdminButton width={131} height={48} onClick={onCancel}>
      {TEXT_CONST.cancel}
    </AdminButton>
    <AdminButton type="primary" width={136} height={48} disabled={disabled} onClick={onSave}>
      {TEXT_CONST.save}
    </AdminButton>
  </div>
);

export default CourseButtons;
