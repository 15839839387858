import './AuthScreen.less';
import { Typography } from 'antd';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useRootStore } from '@/base/hooks/useRootStore';

import { ReactComponent as KZLogo } from '../../assets/logo-kz-new.svg';
import { TEXT_CONST } from './AuthScreen.types';
import BtnSwitch from './components/BtnSwitch';
import LoginForm from './components/LoginForm';
import PhoneForm from './components/PhoneForm';

const BTN_OPTIONS = ['По логину', 'По телефону'];

const AuthScreen = observer(() => {
  const [loginOption, setLoginOption] = useState<string>(BTN_OPTIONS[1]);
  const { authStore } = useRootStore();
  const navigate = useNavigate();

  // Effects
  useEffect(() => {
    if (authStore.isAuth) navigate('/');
  }, [authStore.isAuth, navigate]);
  // Регулярка для отправки логина или телефона без лишних символов
  // loginData.phone.replace(/[^a-zA-Z0-9]/g, "")

  // Handlers
  const handleChangeAuthType = (option: string) => {
    setLoginOption(option);
    authStore.resetStore();
  };

  return (
    <div className="wrapper">
      <div className="block">
        <KZLogo width={225} height={90}/>
        <Typography className="heading">{TEXT_CONST.HEADING}</Typography>
        <BtnSwitch options={BTN_OPTIONS} value={loginOption} onChange={handleChangeAuthType} />
        <div className="inputWrapper">{loginOption === BTN_OPTIONS[0] ? <LoginForm /> : <PhoneForm />}</div>
      </div>
    </div>
  );
});

export default AuthScreen;
