import { ReactComponent as LogoKZ } from 'assets/logo-kz-new.svg';
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router-dom';
import { useMount } from 'react-use';

import { useRootStore } from '@/base/hooks/useRootStore';
import { CoursesModel, CoursesName } from '@/modules/user/models/UserModel';

import QLink from '../QLink/QLink';
import CourseCounter from './components/CourseCounter';
import LogoutButton from './components/LogoutButton';

type Course = {
  title: string;
  count: number;
  path: string;
  testId: string;
  counter: string;
};

const TEXT_CONST: Course[] = [
  {
    title: 'Назначенные курсы',
    count: 6,
    path: '/',
    testId: 'assigned',
    counter: CoursesName.NOT_WATCHED_COURSES,
  },
  {
    title: 'Доступные тесты',
    count: 0,
    path: '/available',
    testId: 'available',
    counter: CoursesName.WATCHED_COURSES,
  },
  {
    title: 'Завершенные курсы',
    count: 1,
    path: '/finished',
    testId: 'finished',
    counter: CoursesName.PASSED_COURSES,
  },
];

const Header = observer(() => {
  const { pathname } = useLocation();
  const { userStore } = useRootStore();

  useMount(() => userStore.getUser());

  return (
    <header className="header">
      <div className="headerContainer">
        <QLink to="/">
          <LogoKZ className="headerLogo" />
        </QLink>
        <div className="headerCardWrapper">
          {TEXT_CONST.map((course) => (
            <CourseCounter
              dataTestId={course.testId}
              key={course.title}
              title={course.title}
              counter={userStore.userCourseCount[course.counter as keyof CoursesModel]}
              active={pathname === course.path}
              path={course.path}
              disabled={userStore.userCourseCount[course.counter as keyof CoursesModel] === 0}
            />
          ))}
        </div>
        <LogoutButton name={userStore.userInfo.name} />
      </div>
    </header>
  );
});

export default Header;
