import { observer } from 'mobx-react-lite';
import { useMount, useUnmount } from 'react-use';

import { useRootStore } from '@/base/hooks/useRootStore';

import CreateSteps from './components/CreateSteps';
import InformationScreen from './components/InformationScreen';
import VideoAddScreen from './components/VideoAddScreen';
import VideosScreen from './components/VideosScreen';
import QuestionsScreen from './screens/QuestionsScreen';
import './styles.less';

const AdminCreateCourseScreen = observer(() => {
  const { adminStore } = useRootStore();
  adminStore.setIsCourseEdit(true);

  useMount(() => () => {
    adminStore.resetStore().then(() => adminStore.setIsCourseEdit(true));
  });

  useUnmount(() => adminStore.resetStore());

  const getActiveScreen = () => {
    switch (adminStore.activeStep) {
      case 0:
        return <InformationScreen />;
      case 1:
        return adminStore.videoUpload ? <VideoAddScreen /> : <VideosScreen />;
      case 2:
        return <QuestionsScreen />;
      default:
        return <></>;
    }
  };

  return (
    <div className="createCourses">
      <CreateSteps />
      {getActiveScreen()}
    </div>
  );
});

export default AdminCreateCourseScreen;
