import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';

import AdminFinishModal from '@/admin/components/AdminFinishModal/AdminFinishModal';
import AdminTestCard from '@/admin/components/AdminTestCard/AdminTestCard';
import { useRootStore } from '@/base/hooks/useRootStore';

const TestScreen = observer(() => {
  const { adminStore } = useRootStore();
  const [activeStep, setActiveStep] = useState(0);
  const [testAnswers, setTestAnswers] = useState<number[]>([]);
  const [isFinishModalOpen, setIsFinishModalOpen] = useState<boolean>(false);
  const question = adminStore.courseInfo.questions[activeStep];
  const questionsLength = adminStore.courseInfo.questions.length;

  const handleSubmit = async (correctAnswer: number[]) => {
    const answers = [...testAnswers, ...correctAnswer];
    setActiveStep(activeStep + 1);
    setTestAnswers(answers);
  };

  useEffect(() => {
    if (activeStep === questionsLength && testAnswers.length > 0) {
      adminStore.passTest(adminStore.courseId, testAnswers).then(() => setIsFinishModalOpen(true));
    }
  }, [testAnswers, activeStep, questionsLength, adminStore]);

  const handleCancel = () => {
    setActiveStep(0);
    setTestAnswers([]);
    setIsFinishModalOpen(false);
  };

  return (
    <>
      <div>
        {activeStep < questionsLength ? (
          <AdminTestCard
            activeStep={activeStep + 1}
            totalSteps={questionsLength}
            onSubmit={handleSubmit}
            {...question}
          />
        ) : (
          <></>
        )}
      </div>
      <AdminFinishModal
        title={adminStore.courseInfo.title}
        result={adminStore.testResult}
        isOpen={isFinishModalOpen}
        onCancel={handleCancel}
      />
    </>
  );
});

export default TestScreen;
