export const timeConvert = (minutes: number, full = true): string => {
  const hours = Math.trunc(minutes / 60);
  const min = minutes % 60;
  return full ? `${hours} ч. ${min} мин.` : `${hours} : ${min}`;
};

export const formatSeconds = (seconds: number): string => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
};
