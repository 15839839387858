import { LoadingOutlined } from '@ant-design/icons';
import { Modal, Spin, Typography } from 'antd';
import plural from 'plural-ru';

import AdminButton from '@/admin/components/AdminButton';
import { ReactComponent as NotPassedIcon } from '@/assets/not-passed-icon.svg';
import { ReactComponent as PassedIcon } from '@/assets/passed-icon.svg';
import { AdminTestResult } from '@/modules/admin/AdminInterface';

import './styles.less';

const TEXT_CONST = {
  passed: 'Поздравляем!',
  not_passed: 'Тест не пройден',
  button: 'Завершить тест',
};

const antIcon = <LoadingOutlined style={{ fontSize: 100 }} spin />;

interface AdminFinishModalProps {
  isOpen: boolean;
  onCancel: () => void;
  result: AdminTestResult;
  title: string;
}

const AdminFinishModal = ({ isOpen, onCancel, result, title }: AdminFinishModalProps) => {
  const finishText =
    result.status?.code === 'failed'
      ? `К сожалению, Вы набрали только ${result.score} ${plural(
          result.score,
          'балл',
          'балла',
          'баллов',
        )} из ${
          result.max
        } возможных по курсу “${title}”. Пройдите курс еще один раз и повторите тестирование`
      : `Вы набрали ${result.score} ${plural(result.score, 'балл', 'балла', 'баллов')} из ${
          result.max
        }
          возможных и завершили курс “${title}”.`;

  return (
    <Modal
      open={isOpen}
      onCancel={onCancel}
      centered
      width={580}
      footer={
        <AdminButton type="primary" height={56} width={347} onClick={onCancel}>
          {TEXT_CONST.button}
        </AdminButton>
      }
      className="adminFinishModal"
    >
      <div className="adminFinishModalWrapper">
        {result.status.name === undefined ? (
          <Spin indicator={antIcon} />
        ) : (
          <>
            {result.status.code === 'failed' ? <NotPassedIcon /> : <PassedIcon />}
            <Typography.Title level={2} className="adminFinishModalHeading">
              {result.status.code === 'failed' ? TEXT_CONST.not_passed : TEXT_CONST.passed}
            </Typography.Title>
            <Typography className="adminFinishModalText">{finishText}</Typography>
          </>
        )}
      </div>
    </Modal>
  );
};

export default AdminFinishModal;
