export interface UserModel {
  id: number;
  phone: string;
  roles: string[];
  name: string;
  halls: number[];
}

export enum CoursesName {
  'PASSED_COURSES' = 'passed_courses',
  'WATCHED_COURSES' = 'watched_courses',
  'NOT_WATCHED_COURSES' = 'not_watched_courses',
}

export interface CoursesModel {
  passed_courses: number;
  watched_courses: number;
  not_watched_courses: number;
}

export interface UserDto {
  user: UserModel;
  courses: CoursesModel;
  is_admin: boolean;
  is_curator: boolean;
}

export const User: UserModel = {
  halls: [],
  id: 0,
  name: '',
  phone: '',
  roles: [],
};

export const Courses: CoursesModel = {
  not_watched_courses: 0,
  passed_courses: 0,
  watched_courses: 0,
};
