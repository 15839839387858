import { ADMIN_ROUTES, APP_ROUTES } from './router.types';

export const RoutePath: Record<APP_ROUTES, string> = {
  [APP_ROUTES.ROOT]: '/',
  [APP_ROUTES.AUTH]: '/auth',
  [APP_ROUTES.AVAILABLE]: '/available',
  [APP_ROUTES.FINISHED]: '/finished',
  [APP_ROUTES.TEST]: '/test/:id',
  [APP_ROUTES.VIDEO]: '/lesson/:id',
  [APP_ROUTES.FINISHED_VIDEO]: '/finished/course/:courseId/video/:videoId',
};

export const AdminPath: Record<ADMIN_ROUTES, string> = {
  [ADMIN_ROUTES.ROOT]: '/admin',
  [ADMIN_ROUTES.COURSE]: '/admin/courses',
  [ADMIN_ROUTES.TEST]: '/admin/tests',
  [ADMIN_ROUTES.TESTS_RESULTS]: '/admin/tests-result',
  [ADMIN_ROUTES.CREATE_COURSE]: '/admin/courses/create',
  [ADMIN_ROUTES.EDIT_COURSE]: '/admin/courses/edit/:id',
};
