import lodash from 'lodash';
import { makeAutoObservable } from 'mobx';

import { Course, CourseDto, CourseInfo, Video } from '../common/models/Course';
import {
  AdminCourses,
  AdminTestListItem,
  AdminTestResult,
  AdminTestResultDto,
  CreateCourse,
  PreparedTestsResults,
  PreparedUserTestsResults,
  QuestionCardForm,
  Statistic,
  StatisticDto,
  UploadFileForm,
} from './AdminInterface';
import AdminService from './AdminService';

export default class AdminStore {
  loading = false;

  errorMessage = '';

  // Номер шага при создании курса.
  // 0-информация о курсе 1-добавление видео 2-добавление тестирования
  activeStep = 0;

  // После создания курса записываем в стор номер курса, для добавления видео по id
  courseId = 0;

  // Флаг переключает экран между добавлением видео и всеми видео при создании курса
  videoUpload = true;

  // Флаг переключает экран после успешного создания курса
  courseUploaded = false;

  // Список видео по id курса
  uploadedVideos: Video[] = [];

  courseTitle = '';

  uploadedCourses: AdminCourses[] = [];

  testList: AdminTestListItem[] = [];

  testsTotal = 0;

  statisticInfo: Statistic = StatisticDto;

  // Массив для созданных вопросов
  createdQuestions: QuestionCardForm[] = [];

  // Минимальное кол-во баллов для прохождения курса
  minScore = 0;

  // Срок прохождения курса
  durationDate = 30;

  // Попап для настройки видимости курса
  isPopupOpened = false;

  courseInfo = CourseDto;

  // Флаг для включения режима редактирования курса
  isCourseEdit = false;

  // Флаг для того, чтобы понять что мы на странице редактирования
  isEditScreen = false;

  // Флаг для того, чтобы посмотреть отчет по курсам пользователя
  isUserInfo = false;

  // Информация по завершенным курсам по id
  userInfoResults: PreparedUserTestsResults[] = [];

  // Вкладка тесты в админке(отключена на данный момент)
  testResult: AdminTestResult = AdminTestResultDto;

  // Вкладка результаты тестов в админке
  testsResults: PreparedTestsResults[] = [];

  TIME_START_INDEX = -5;

  private adminService;

  constructor() {
    makeAutoObservable(this);

    this.adminService = new AdminService();
  }

  createCourse = (values: CreateCourse) => {
    this.setLoading(true);

    this.adminService
      .createCourse(values)
      .then(({ data }) => {
        this.setCourseId(data.id);
      })
      .then(() => {
        this.nextActiveStep();
      })
      .catch((error) => this.setErrorMessage(error))
      .finally(() => this.setLoading(false));
  };

  uploadFile = (courseId: number, values: UploadFileForm) => {
    this.setLoading(true);

    this.adminService
      .uploadFile(courseId, values)
      .then(() => {
        this.setVideoUpload(false);
        this.getVideos(this.courseId);
      })
      .catch((error) => this.setErrorMessage(error))
      .finally(() => this.setLoading(false));
  };

  getCourses = (isActive?: string) => {
    this.setLoading(true);

    this.adminService
      .getCourses(isActive)
      .then(({ data }) => {
        this.setUploadedCourses(data);
      })
      .catch((error) => this.setErrorMessage(error))
      .finally(() => {
        this.setLoading(false);
      });
  };

  getTestList = (tabId?: string, pageNumber?: number, pageSize?: number) => {
    this.setLoading(true);

    this.adminService
      .getTests(tabId, pageNumber, pageSize)
      .then(({ data }) => {
        const testList = data.items.map((item, index) => ({
          ...item,
          key: item.passed_at + index,
          score: [item.score, item.max_score],
          status: item.status.name,
          appointment_at: [
            item.appointment_at.slice(0, this.TIME_START_INDEX),
            item.appointment_at.slice(this.TIME_START_INDEX),
          ],
          passed_at: [
            item.passed_at.slice(0, this.TIME_START_INDEX),
            item.passed_at.slice(this.TIME_START_INDEX),
          ],
        }));
        this.setTestList(testList);
        this.setTestsTotal(data.pagination.total);
      })
      .catch((error) => this.setErrorMessage(error))
      .finally(() => {
        this.setLoading(false);
      });
  };

  getTestsResults = (searchValue = '') => {
    this.setLoading(true);

    this.adminService
      .getTestsResults(searchValue)
      .then(({ data }) => {
        const preparedData = data.map((item) => ({
          ...item,
          total: `${item.passed}/${item.total}`,
          status: item.status.name,
          key: item.id,
          isClickable: item.passed > 0,
        }));
        this.setTestsResults(preparedData);
      })
      .catch((error) => this.setErrorMessage(error))
      .finally(() => this.setLoading(false));
  };

  getUserTestsResults = (userId: number) => {
    this.setLoading(true);

    this.adminService
      .getUserTestsResults(userId)
      .then(({ data }) => {
        const preparedData = data.items.map((item, index) => ({
          ...item,
          key: item.appointment_at + index,
          status: item.status.name,
          score: [item.score, item.max_score],
          appointment_at: [
            item.appointment_at.slice(0, this.TIME_START_INDEX),
            item.appointment_at.slice(this.TIME_START_INDEX),
          ],
          passed_at:
            item.passed_at !== undefined
              ? [
                  item.passed_at.slice(0, this.TIME_START_INDEX),
                  item.passed_at.slice(this.TIME_START_INDEX),
                ]
              : [''],
        }));
        this.setUserInfoResults(preparedData);
        this.setIsUserInfo(true);
      })
      .catch((error) => this.setErrorMessage(error))
      .finally(() => this.setLoading(false));
  };

  getVideos = (courseId: number) => {
    this.setLoading(true);
    this.setCourseId(courseId);

    this.adminService
      .getVideos(courseId)
      .then(({ data }) => {
        this.setCourseTitle(data.description);
        this.setUploadedVideos(data.videos);
        this.setCourseInfo(data);
        this.setMinScore(data.min);
      })
      .catch((error) => this.setErrorMessage(error))
      .finally(() => this.setLoading(false));
  };

  deleteVideo = (courseId: number, videoId: number) => {
    this.setLoading(true);

    this.adminService
      .deleteVideo(courseId, videoId)
      .then(() => this.getVideos(this.courseId))
      .catch((error) => this.setErrorMessage(error))
      .finally(() => this.setLoading(false));
  };

  deleteCourse = async (courseId: number) => {
    this.setLoading(true);

    return this.adminService
      .deleteCourse(courseId)
      .catch((error) => this.setErrorMessage(error))
      .finally(() => this.setLoading(false));
  };

  getStatistic = () => {
    this.setLoading(true);

    this.adminService
      .getStatistic()
      .then(({ data }) => {
        this.setStatisticInfo(data);
      })
      .catch((error) => this.setErrorMessage(error))
      .finally(() => this.setLoading(false));
  };

  changeVideoTitle = async (courseId: number, videoId: number, name: string) => {
    this.setLoading(true);

    return this.adminService
      .changeVideoTitle(courseId, videoId, name)
      .catch((error) => this.setErrorMessage(error))
      .finally(() => this.setLoading(false));
  };

  // Setters
  setLoading = (state: boolean) => {
    this.loading = state;
  };

  setCourseId = (state: number) => {
    this.courseId = state;
  };

  setVideoUpload = (state: boolean) => {
    this.videoUpload = state;
  };

  setUploadedCourses = (courses: AdminCourses[]) => {
    this.uploadedCourses = courses;
  };

  setTestList = (list: AdminTestListItem[]) => {
    this.testList = list;
  };

  setTestsTotal = (total: number) => {
    this.testsTotal = total;
  };

  setUploadedVideos = (videos: Video[]) => {
    this.uploadedVideos = videos;
  };

  setStatisticInfo = (data: Statistic) => {
    this.statisticInfo = data;
  };

  setCourseTitle = (data: string) => {
    this.courseTitle = data;
  };

  setCourseInfo = (data: Course) => {
    this.courseInfo = data;
  };

  setCreatedQuestions = (item: QuestionCardForm[]) => {
    this.createdQuestions = item;
  };

  setMinScore = (score: number | null) => {
    if (score) this.minScore = score;
  };

  setDurationDate = (duration: number) => {
    this.durationDate = duration;
  };

  setTestResults = (data: AdminTestResult) => {
    this.testResult = data;
  };

  setTestsResults = (data: PreparedTestsResults[]) => {
    this.testsResults = data;
  };

  setUserInfoResults = (data: PreparedUserTestsResults[]) => {
    this.userInfoResults = data;
  };

  submitCourse = () => {
    this.setLoading(true);

    const formData = new FormData();

    for (let i = 0; i < this.createdQuestions.length; i++) {
      const { question } = this.createdQuestions[i];
      formData.append(`questions[${i}][name]`, question);

      const { answers, files } = this.createdQuestions[i];
      for (let j = 0; j < answers.length; j++) {
        if (answers[j].name.length >= 1) {
          formData.append(`questions[${i}][answers][${j}][name]`, answers[j].name);
          formData.append(`questions[${i}][answers][${j}][correct]`, String(answers[j].correct));
        }
      }
      if (files) {
        for (let k = 0; k < files.length; k++) {
          formData.append(`questions[${i}][files][${k}]`, files[k].originFileObj as string | Blob);
        }
      }
    }
    formData.append('min', String(this.minScore));
    formData.append('expiration_at', String(this.durationDate === 0 ? '' : this.durationDate));

    this.adminService
      .submitCourse(this.courseId, formData)
      .then(() => {
        this.setIsPopupOpened(true);
      })
      .catch((error) => this.setErrorMessage(lodash.get(error?.response?.data?.errors, [0])))
      .finally(() => this.setLoading(false));
  };

  setCourseActive = async (isActive: boolean) => {
    this.setLoading(false);

    return this.adminService
      .setCourseActive(this.courseId, isActive)
      .then(() => {
        this.setIsPopupOpened(false);
      })
      .catch((error) => this.setErrorMessage(error))
      .finally(() => {
        this.setLoading(false);
      });
  };

  setIsCourseEdit = (newState?: boolean) => {
    if (typeof newState === 'boolean') {
      this.isCourseEdit = newState;
    } else {
      this.isCourseEdit = !this.isCourseEdit;
    }
  };

  changeCourseInfo = (courseId: number, values: CourseInfo) => {
    this.setLoading(true);
    this.adminService
      .changeCourseInfo(courseId, values)
      .then(() => {
        this.setIsCourseEdit();
        this.getVideos(courseId);
      })
      .catch((error) => this.setErrorMessage(error))
      .finally(() => {
        this.setLoading(false);
      });
  };

  passTest = async (courseId: number, values: number[]) => {
    this.setLoading(true);
    return this.adminService
      .passTest(courseId, values)
      .then(({ data }) => this.setTestResults(data))
      .catch((error) => this.setErrorMessage(error?.response?.data?.description || error?.response?.data?.errors[0]))
      .finally(() => this.setLoading(false));
  };

  deleteCreatedQuestion = (questionIndex: number) => {
    this.createdQuestions = this.createdQuestions.filter((item, index) => index !== questionIndex);
  };

  setIsPopupOpened = (isOpen: boolean) => {
    this.isPopupOpened = isOpen;
  };

  setCourseUploaded = (state: boolean) => {
    this.courseUploaded = state;
  };

  setIsEditScreen = (state: boolean) => {
    this.isEditScreen = state;
  };

  setIsUserInfo = (state: boolean) => {
    this.isUserInfo = state;
  };

  setErrorMessage = (message: string) => {
    this.errorMessage = message;
  };

  nextActiveStep = () => {
    this.activeStep += 1;
  };

  previousActiveStep = () => {
    this.activeStep -= 1;
  };

  resetStore = async () => {
    this.activeStep = 0;
    this.uploadedVideos = [];
    this.createdQuestions = [];
    this.minScore = 0;
    this.errorMessage = '';
    this.courseId = 0;
    this.isCourseEdit = false;
  };
}
