import { AxiosResponse } from 'axios';

import $api from '@/base/api';

import { Course, FinishedCourse } from '../common/models/Course';

export default class TestService {
  getCourseInfo = async (id: number): Promise<AxiosResponse<Course>> => $api.get(`/api/desktop/v1/course/${id}`);

  startAttempt = async (id: number) => $api.post(`/api/desktop/v1/course/${id}/attempt`);

  answerQuestion = (id: number, answers: number[]): Promise<AxiosResponse<FinishedCourse>> => $api.post(`/api/desktop/v1/course/${id}/answer`, { answers });

  getResults = async (id: number): Promise<AxiosResponse<FinishedCourse>> => $api.get(`/api/desktop/v1/course/${id}/attempt`);
}
