import { Tabs, TabsProps, Typography } from 'antd';
import { ReactComponent as BackArrow } from 'assets/arrow-left.svg';
import { ReactComponent as PencilIcon } from 'assets/pencil-icon.svg';
import { ReactComponent as TrashIcon } from 'assets/trash-icon.svg';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useMount, useUnmount } from 'react-use';

import AdminButton from '@/admin/components/AdminButton';
import AdminConfirmPopup from '@/admin/components/AdminConfirmPopup';
import AdminDeletePopup from '@/admin/components/AdminDeletePopup';
import VideoAddScreen from '@/admin/screens/AdminCreateCourseScreen/components/VideoAddScreen';
import { useRootStore } from '@/base/hooks/useRootStore';
import { AdminPath } from '@/router';

import InformationScreen from './screens/InformationEditScreen';
import QuestionsEditScreen from './screens/QuestionsEditScreen';
import TestScreen from './screens/TestScreen';
import VideoEditScreen from './screens/VideoEditScreen';

import './styles.less';

const items: TabsProps['items'] = [
  {
    key: '0',
    label: 'Информация',
  },
  {
    key: '1',
    label: 'Видеоуроки',
  },
  {
    key: '2',
    label: 'Тестирование',
  },
  {
    key: '3',
    label: 'Проверка теста',
  },
];
const TEXT_CONST = {
  edit: 'Редактировать',
  delete: 'Удалить',
  modalText:
    'Вы собираетесь удалить курс. Удаление производится без возможности восстановления. Вы уверены, что хотите продолжить удаление?',
  confirmText: 'Уверены, что хотите выйти из режима редактирования курса? Изменения не сохранятся.',
};

const AdminEditScreen = observer(() => {
  const { adminStore, userStore } = useRootStore();
  const { id } = useParams();
  const [activeTab, setActiveTab] = useState<number>(0);
  const [isConfirmOpened, setIsConfirmOpened] = useState<boolean>(false);
  const [isDeleteOpened, setIsDeleteOpened] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleBack = () => {
    adminStore.resetStore();
    navigate(AdminPath.courses);
  };

  // Effects
  useMount(() => {
    adminStore.getVideos(Number(id));
    adminStore.setVideoUpload(false);
    adminStore.setIsEditScreen(true);
  });
  useUnmount(() => {
    adminStore.setVideoUpload(true);
    adminStore.resetStore();
    adminStore.setIsEditScreen(false);
  });

  // Handlers
  const handleDelete = async () => {
    await adminStore.deleteCourse(Number(id));
    setIsDeleteOpened(false);
    navigate('/admin/courses');
  };

  const handleChangeTab = (tabId: string) => {
    adminStore.setErrorMessage('');
    setActiveTab(Number(tabId));
    adminStore.setIsCourseEdit(false);
  };

  // Render
  const getActiveScreen = () => {
    switch (activeTab) {
      case 0:
        return <InformationScreen />;
      case 1:
        return adminStore.videoUpload ? <VideoAddScreen /> : <VideoEditScreen />;
      case 2:
        return <QuestionsEditScreen />;
      case 3:
        return <TestScreen />;
      default:
        return <></>;
    }
  };

  return (
    <>
      <div className="editCourses">
        <div className="editCoursesWrapper">
          <div className="editCoursesHeader">
            <Typography.Title level={2} className="editCoursesTitle">
              <BackArrow
                onClick={adminStore.isCourseEdit ? () => setIsConfirmOpened(true) : handleBack}
                className="editCourseBackBtn"
              />
              {adminStore.courseInfo.title}
            </Typography.Title>
            {userStore.isAdmin && !adminStore.isCourseEdit && (
              <div className="editCoursesBtnWrapper">
                <AdminButton
                  height={48}
                  icon={<TrashIcon />}
                  onClick={() => setIsDeleteOpened(true)}
                >
                  {TEXT_CONST.delete}
                </AdminButton>
                <AdminButton
                  height={48}
                  icon={<PencilIcon />}
                  onClick={() => adminStore.setIsCourseEdit()}
                >
                  {TEXT_CONST.edit}
                </AdminButton>
              </div>
            )}
          </div>
          <Tabs items={items} className="editCoursesTabs" onChange={handleChangeTab} />
        </div>
        <div className="editCoursesActiveTab">{getActiveScreen()}</div>
      </div>
      <AdminDeletePopup
        isOpened={isDeleteOpened}
        onCancel={() => setIsDeleteOpened(false)}
        onSubmit={handleDelete}
        modalText={TEXT_CONST.modalText}
        loading={adminStore.loading}
      />
      <AdminConfirmPopup
        isOpened={isConfirmOpened}
        onCancel={() => setIsConfirmOpened(false)}
        onSubmit={handleBack}
        modalText={TEXT_CONST.confirmText}
      />
    </>
  );
});

export default AdminEditScreen;
