import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import { useMount } from 'react-use';

import AdminButton from '@/admin/components/AdminButton';
import AdminVideoAddCard from '@/admin/components/AdminVideoAddCard';
import AdminVideoCard from '@/admin/components/AdminVideoCard';
import { useRootStore } from '@/base/hooks/useRootStore';

import './styles.less';

const TEXT_CONST = {
  cancel: 'Отменить',
  save: 'Сохранить',
};

const VideoEditScreen = observer(() => {
  const { adminStore, userStore } = useRootStore();
  const { id } = useParams();

  useMount(() => adminStore.setCourseId(Number(id)));

  return (
    <>
      <div className="editVideoScreen">
        {adminStore.courseInfo.videos.map((item) => (
          <AdminVideoCard key={item.id} {...item} />
        ))}
        {userStore.isAdmin && adminStore.isCourseEdit && <AdminVideoAddCard />}
      </div>
      {adminStore.isCourseEdit && (
        <div className="editVideoScreenBtn">
          <AdminButton height={48} onClick={() => adminStore.setIsCourseEdit()}>
            {TEXT_CONST.cancel}
          </AdminButton>
          <AdminButton type="primary" height={48} onClick={() => adminStore.setIsCourseEdit()}>
            {TEXT_CONST.save}
          </AdminButton>
        </div>
      )}
    </>
  );
});

export default VideoEditScreen;
