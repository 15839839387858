import { Input, Typography } from 'antd';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';

import { useRootStore } from '@/base/hooks/useRootStore';

import AdminButton from '../../../components/AdminButton/AdminButton';

const TEXT_CONST = {
  name: 'Название:',
  namePlaceholder: 'Дайте курсу название',
  section: 'Раздел:',
  sectionPlaceholder: 'Выберите раздел',
  access: 'Доступ:',
  cancel: 'Отменить',
  nextStep: 'Следующий шаг',
  adminVisibility: 'Виден только администраторам',
  adminSpan: 'По умолчанию курс будет виден только администраторам',
  allVisibility: 'Открыт всем',
};

interface FormState {
  name: string;
  description: string;
}

const InformationScreen = observer(() => {
  const { adminStore } = useRootStore();
  const [formState, setFormState] = useState<FormState>({
    name: '',
    description: '',
  });

  const handleSubmit = () => {
    adminStore.createCourse(formState);
  };

  return (
    <div className="informationScreen">
      <div className="informationScreenRow">
        <Typography className="informationScreenText">{TEXT_CONST.name}</Typography>
        <Input
          placeholder={TEXT_CONST.namePlaceholder}
          className="informationScreenInput"
          name="name"
          onChange={(event) => setFormState({
            ...formState,
            [event.target.name]: event.target.value,
          })}
          maxLength={255}
        />
      </div>
      <div className="informationScreenRow">
        <Typography className="informationScreenText">{TEXT_CONST.section}</Typography>
        <Input
          placeholder={TEXT_CONST.sectionPlaceholder}
          className="informationScreenInput"
          name="description"
          onChange={(event) => setFormState({
            ...formState,
            [event.target.name]: event.target.value,
          })}
          maxLength={255}
        />

      </div>
      {/* <div className="informationScreenRow"> */}
      {/*  <Typography className="informationScreenText">{TEXT_CONST.access}</Typography> */}
      {/*  <div> */}
      {/*    <Radio.Group */}
      {/*      value={formState.visibility} */}
      {/*      onChange={(e) => setFormState({ ...formState, visibility: e.target.value })} */}
      {/*    > */}
      {/*      <Radio value="admin">{TEXT_CONST.adminVisibility}</Radio> */}
      {/*      <Radio value="all">{TEXT_CONST.allVisibility}</Radio> */}
      {/*    </Radio.Group> */}
      {/*    {formState.visibility === 'admin' && ( */}
      {/*      <Typography className="informationScreenSpan">{TEXT_CONST.adminSpan}</Typography> */}
      {/*    )} */}
      {/*  </div> */}
      {/* </div> */}
      <div className="informationScreenRow">
        <div />
        <div className="informationScreenButtons">
          <AdminButton width={130} height={48}>
            {TEXT_CONST.cancel}
          </AdminButton>
          <AdminButton
            type="primary"
            width={186}
            height={48}
            disabled={!formState.name || !formState.description}
            onClick={handleSubmit}
          >
            {TEXT_CONST.nextStep}
          </AdminButton>
        </div>
      </div>
    </div>
  );
});

export default InformationScreen;
