import { makeAutoObservable } from 'mobx';

import VideoService from './VideoService';
import { VideoCourse, VideoCourseDto } from './models/VideoCourse';

export default class VideoStore {
  loading = false;

  errorMessage = '';

  videoCourse: VideoCourse = VideoCourseDto;

  private videoService;

  constructor() {
    makeAutoObservable(this);

    this.videoService = new VideoService();
  }

  getVideos = (id: number) => {
    this.setLoading(true);

    this.videoService
      .getVideoCourse(id)
      .then(({ data }) => this.setVideoCourse(data))
      .catch((error) => this.setErrorMessage(error.response?.data?.errors?.[0]))
      .finally(() => this.setLoading(false));
  };

  setWatchedTime = (videoId: number, time: number) => {
    const isWatched = this.videoCourse.videos.find((video) => video.id === videoId)?.is_watched;
    if (!isWatched) this.videoService.setWatchedTime(this.videoCourse.id, videoId, time);
  };

  setVideoWatched = (videoId: number) => {
    this.videoCourse = {
      ...this.videoCourse,
      videos: this.videoCourse.videos.map((video) => {
        if (video.id === videoId)
          return {
            ...video,
            progress: 100,
            is_watched: true,
          };
        return video;
      }),
    };
  };

  // Setters
  setErrorMessage = (message: string) => {
    this.errorMessage = message;
  };

  setVideoCourse = (state: VideoCourse) => {
    this.videoCourse = state;
  };

  setLoading = (state: boolean) => {
    this.loading = state;
  };
}
