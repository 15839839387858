export interface Course {
  description: string;
  id: number;
  images: string[];
  title: string;
  questions: Question[];
  videos: Video[];
  is_started: boolean | null;
  question_count: number;
  current_question: number;
  is_active: boolean;
  min: number;
}

export interface Question {
  id: number;
  name: string;
  description: string;
  answers: Answer[];
  images: string[];
  is_multiple: boolean;
  correct_count: number;
}

export interface Answer {
  id: number;
  name: string;
  correct: boolean;
}

export interface Video {
  id: number;
  name: string;
  preview?: string;
}

export interface CourseInfo {
  name: string;
  description: string;
  is_active: boolean;
}

export const CourseDto: Course = {
  description: '',
  id: 0,
  title: '',
  questions: [],
  videos: [],
  images: [],
  is_started: null,
  question_count: 0,
  current_question: 0,
  is_active: false,
  min: 0,
};

export interface FinishedCourse {
  max: number;
  min: number;
  score: number;
  question_count: number;
  correct_question_count: number;
  id: number;
  status: FinishedStatus;
  message?: string;
}

export interface FinishedStatus {
  id: number;
  code: string;
  name: string;
}

export const FinishedDto: FinishedCourse = {
  correct_question_count: 0,
  id: 0,
  max: 0,
  min: 0,
  question_count: 0,
  score: 0,
  status: {
    id: 0,
    code: '',
    name: '',
  },
};
