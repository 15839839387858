import { orderBy } from 'lodash';
import { makeAutoObservable } from 'mobx';

import MainService from './MainService';
import { Course, FinishedCourse } from './models/Course';

export default class MainStore {
  loading = false;

  errorMessage = '';

  courses: Course[] = [];

  watchedCourses: Course[] = [];

  passedCourses: FinishedCourse[] = [];

  // Search and filter
  searchResults: FinishedCourse[] = [];

  searchValue = '';

  private mainService: MainService;

  constructor() {
    makeAutoObservable(this);

    this.mainService = new MainService();
  }

  getCourses = () => {
    this.setLoading(true);
    this.mainService
      .getCourses()
      .then(({ data }) => {
        this.setCourses(data);
      })
      .catch((error) =>
        this.setErrorMessage(error?.response.data?.description || error?.response?.data?.errors[0]),
      )
      .finally(() => this.setLoading(false));
  };

  getWatchedCourses = () => {
    this.setLoading(true);
    this.mainService
      .getWatchedCourses()
      .then(({ data }) => {
        this.setWatchedCourses(data);
      })
      .catch((error) => {
        this.setErrorMessage(error?.response.data?.description || error?.response?.data?.errors[0]);
      })
      .finally(() => this.setLoading(false));
  };

  getPassedCourses = () => {
    this.setLoading(true);
    this.mainService
      .getPassedCourses()
      .then(({ data }) => {
        this.setPassedCourses(orderBy(data, 'date', 'desc'));
      })
      .catch((error) =>
        this.setErrorMessage(
          error?.response?.data?.description || error?.response?.data?.errors[0],
        ),
      )
      .finally(() => this.setLoading(false));
  };

  // Setters
  setLoading = (state: boolean) => {
    this.loading = state;
  };

  setCourses = (courses: Course[]) => {
    this.courses = courses;
  };

  setWatchedCourses = (courses: Course[]) => {
    this.watchedCourses = courses;
  };

  setPassedCourses = (courses: FinishedCourse[]) => {
    this.passedCourses = courses;
  };

  setErrorMessage = (message: string) => {
    this.errorMessage = message;
  };

  setSearchValue = (value: string) => {
    this.searchValue = value;
  };

  setSearchResults = () => {
    this.searchResults = this.passedCourses.filter(
      (item) =>
        item.title.toLowerCase().includes(this.searchValue.toLowerCase()) ||
        item.video_name.toLowerCase().includes(this.searchValue.toLowerCase()),
    );
  };

  setSortResults = (field: keyof FinishedCourse, direction: 'asc' | 'desc') =>
    this.searchResults.sort((a, b) => {
      const aValue = String(a[field]);
      const bValue = String(b[field]);

      if (direction === 'asc') {
        return aValue.localeCompare(bValue);
      }
      return bValue.localeCompare(aValue);
    });
}
