import { AxiosResponse } from 'axios';

import $api from '@/base/api';

import { Course, FinishedCourse } from './models/Course';

export default class MainService {
  getCourses = async (): Promise<AxiosResponse<Course[]>> => $api.get('/api/desktop/v1/course');

  getWatchedCourses = async (): Promise<AxiosResponse<Course[]>> => $api.get('/api/desktop/v1/course/watched');

  getPassedCourses = async (): Promise<AxiosResponse<FinishedCourse[]>> => $api.get('/api/desktop/v1/course/passed');
}
