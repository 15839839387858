import { observer } from 'mobx-react-lite';

import { ReactComponent as LogoKZ } from '@/assets/admin-logo-icon.svg';
import { ReactComponent as Logout } from '@/assets/admin-logout-icon.svg';
import { useRootStore } from '@/base/hooks/useRootStore';

import AdminUser from './components/AdminUser';

import './styles.less';

const AdminHeader = observer(() => {
  const { userStore, authStore } = useRootStore();
  return (
    <div className="adminHeader">
      <div className="adminHeaderWrapper">
        <LogoKZ height={32} width={58} />
        <div className="adminHeaderRight">
          <AdminUser user={userStore.userInfo.name} />
          <Logout width={28} onClick={() => authStore.logout()} className="adminHeaderLogout" />
        </div>
      </div>
    </div>
  );
});

export default AdminHeader;
