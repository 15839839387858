import type { UploadFile } from 'antd/es/upload/interface';

import { UserModel } from '../user/models/UserModel';

export interface CreateCourse {
  name: string;
  description: string;
}

export interface CreateCourseResponse {
  id: number;
  name: string;
  description: string;
  created_by: UserModel;
  created_at: string;
  videos: [];
}

export interface UploadFileForm {
  name: string;
  file: UploadFile | null;
}

export interface AdminCourses {
  title: string;
  videos: AdminVideo[];
  created_at: string;
  images: string[];
  id: number;
  is_active: boolean;
}

export interface AdminVideo {
  name: string;
  uploaded: boolean;
}

// Ниже отключены карточки не нужные для бизнеса
export interface Statistic {
  all_users: number;
  not_start_courses: number;
  // not_start_users: number;
  // passed_courses_users: number;
  all_passed: number;
  // start_training: number;
  // courses_count: number;
  not_started: number;
}

export const StatisticDto: Statistic = {
  all_passed: 0,
  all_users: 0,
  // courses_count: 0,
  not_start_courses: 0,
  // not_start_users: 0,
  // passed_courses_users: 0,
  // start_training: 0,
  not_started: 0,
};

export interface TestsResultsResponse {
  id: number;
  name: string;
  hall_id: number;
  total: string;
  passed: number;
  status: {
    id: number;
    code: string;
    name: string;
  };
}

export interface PreparedTestsResults {
  id: number;
  name: string;
  hall_id: number;
  total: string;
  passed: number;
  status: string;
  isClickable: boolean;
}

export interface PreparedUserTestsResults {
  id: number;
  name: string;
  hall_id: number;
  max_score: number;
  course_name: string;
  key: string;
  status: string;
  appointment_at: string[];
  passed_at: string[];
}

export interface QuestionCardForm {
  isMultiple: boolean;
  question: string;
  answers: Answers[];
  files: UploadFile[] | undefined;
  checkedIndex: number | null;
  withPicture: boolean;
  images: string[];
  hoveredIndex: number;
}

export interface Answers {
  name: string;
  correct: boolean;
}

export const questionCardState: QuestionCardForm = {
  isMultiple: false,
  question: '',
  answers: [
    { name: '', correct: false },
    { name: '', correct: false },
  ],
  files: undefined,
  checkedIndex: null,
  withPicture: false,
  images: [],
  hoveredIndex: -1,
};

export interface QuestionsResponse {
  id: number;
  name: string;
  description: string;
  answers: Answers[];
  images: string[];
}

export interface AdminTestItemResponse {
  name: string;
  course_name: string;
  appointment_at: string;
  passed_at: string;
  status: {
    id: number;
    code: string;
    name: string;
  };
  min: number;
  score: number;
  max_score: number;
}

export interface AdminTestUserInfoResponse {
  appointment_at: string;
  course_name: string;
  hall_id: number;
  id: number;
  max_score: number;
  min: number;
  name: string;
  passed_at: string;
  percent: number;
  score: number;
  status: {
    id: number;
    code: string;
    name: string;
  };
}

export interface AdminTestsResponse {
  pagination: {
    pages: number;
    current_page: number;
    total: number;
  };
  items: AdminTestItemResponse[];
}

export interface UserInfoResponse {
  items: AdminTestUserInfoResponse[];
}

export interface AdminTestListItem {
  name: string;
  course_name: string;
  appointment_at: string[];
  passed_at: string[];
  status: string;
  score: number[];
}

export interface AdminTestResult {
  max: number;
  min: number;
  score: number;
  status: {
    code: string;
    name: string;
  };
}

export const AdminTestResultDto: AdminTestResult = {
  max: 0,
  min: 0,
  score: 0,
  status: { code: '', name: '' },
};

export const TestsResultsDto: TestsResultsResponse = {
  hall_id: 0,
  id: 0,
  name: '',
  passed: 0,
  status: { code: '', id: 0, name: '' },
  total: '',
};
