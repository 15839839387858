import QSlider from '@/components/QSlider';
import QTypography from '@/components/QTypography';

import './styles.less';

const formatTime = (time: number | undefined) => {
  if (time && !Number.isNaN(time)) {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    const formatSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
    return `${minutes}:${formatSeconds}`;
  }
  return '0:00';
};

interface ProgressBarProps {
  value: number;
  onChange(seconds: number): void;
  duration: number;
  disabled?: boolean;
}

const ProgressBar = ({ value, onChange, duration, disabled = false }: ProgressBarProps) => (
  <div className="VideoPlayerProgressBar">
    <QSlider
      value={value}
      onChange={onChange}
      max={duration}
      tooltip={{ formatter: formatTime }}
      railStyle={{ backgroundColor: 'rgba(255, 255, 255, 0.50)' }}
      trackStyle={{ backgroundColor: '#FFFFFF' }}
      disabled={disabled}
    />
    <QTypography>{formatTime(duration)}</QTypography>
  </div>
);

export default ProgressBar;
