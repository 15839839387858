import { Modal, Typography } from 'antd';

import './styles.less';
import AdminButton from '@/admin/components/AdminButton';

const TEXT_CONST = {
  cancel: 'Отменить',
  submit: 'Да, удалить',
  title: 'Подтверждение удаления',
};

interface AdminDeleteProps {
  isOpened: boolean;
  onCancel: () => void;
  onSubmit: () => void;
  modalText: string;
  loading: boolean;
}

const AdminDeletePopup = ({
  isOpened,
  onCancel,
  onSubmit,
  modalText,
  loading,
}: AdminDeleteProps) => {
  const Footer = () => (
    <div className="deleteModalFooter">
      <AdminButton height={40} onClick={onCancel}>
        {TEXT_CONST.cancel}
      </AdminButton>
      <AdminButton danger={true} type="primary" onClick={onSubmit} loading={loading}>
        {TEXT_CONST.submit}
      </AdminButton>
    </div>
  );
  return (
    <Modal
      open={isOpened}
      onCancel={onCancel}
      title={<Typography>{TEXT_CONST.title}</Typography>}
      centered
      footer={<Footer />}
      className="deleteModal"
    >
      <Typography className="deleteModalText">{modalText}</Typography>
    </Modal>
  );
};

export default AdminDeletePopup;
