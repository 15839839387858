import { Typography } from 'antd';
import { ReactComponent as HatIcon } from 'assets/hat-icon.svg';
import { ReactComponent as HomeIcon } from 'assets/home-icon.svg';
import { ReactComponent as NotebookIcon } from 'assets/notebook-icon.svg';
import clsx from 'clsx';

import QLink from '../../../../components/QLink/QLink';

interface SidebarItemProps {
  iconName: 'home' | 'hat' | 'notebook';
  name: string;
  path: string;
  active: boolean;
}

const SidebarItem = ({
  iconName, name, path, active,
}: SidebarItemProps) => {
  const getBadge = () => {
    switch (iconName) {
      case 'home':
        return <HomeIcon />;
      case 'hat':
        return <HatIcon />;
      case 'notebook':
        return <NotebookIcon />;
      default:
        return <></>;
    }
  };

  return (
    <QLink to={path}>
      <div
        className={clsx('adminSidebarItem', {
          '-active': active,
        })}
      >
        {getBadge()}
        <Typography className="adminSidebarText">{name}</Typography>
      </div>
    </QLink>
  );
};

export default SidebarItem;
