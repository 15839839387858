import clsx from 'clsx';
import { RefObject } from 'react';

import { ReactComponent as FullscreenButton } from '@/assets/fullscreen-button.svg';
import { ReactComponent as FullscreenExitButton } from '@/assets/fullscreen-exit-button.svg';
import { ReactComponent as ArrowVideoButton } from '@/assets/next-video-button.svg';
import { ReactComponent as PauseButton } from '@/assets/pause-button.svg';
import { ReactComponent as PlayButton } from '@/assets/play-button.svg';
import { ReactComponent as ReplayButton } from '@/assets/replay-button.svg';

import ProgressBar from '../ProgressBar';
import Volume from '../Volume';

import './styles.less';

interface ControlsProps {
  videoRef: RefObject<HTMLVideoElement>;
  ended: boolean;
  isWatched: boolean;
  inFullscreen: boolean;
  disablePrev?: boolean;
  disableNext?: boolean;
  duration: number;
  progress: number;
  onProgressChange(seconds: number): void;
  onPauseClick(): void;
  onPlayClick(): void;
  onReplayClick(): void;
  onPrevClick(): void;
  onNextClick(): void;
  onFullscreenClick(): void;
}
const Controls = ({
  videoRef,
  ended,
  duration,
  onReplayClick,
  onPauseClick,
  onPrevClick,
  onNextClick,
  onPlayClick,
  progress,
  onProgressChange,
  inFullscreen,
  onFullscreenClick,
  disablePrev,
  disableNext,
  isWatched,
}: ControlsProps) => (
  <div className="controlsContainer">
    {!videoRef.current?.paused && (
      <PauseButton width={67} height={67} className="controlsPlayButton" onClick={onPauseClick} />
    )}
    {!ended && Boolean(videoRef.current?.paused) && (
      <PlayButton width={67} height={67} className="controlsPlayButton" onClick={onPlayClick} />
    )}
    {ended && Boolean(videoRef.current?.paused) && (
      <ReplayButton width={67} height={67} className="controlsPlayButton" onClick={onReplayClick} />
    )}

    <ProgressBar
      duration={duration}
      value={progress}
      onChange={onProgressChange}
      disabled={!isWatched}
    />
    <div className="controlsButtonGroup">
      <ArrowVideoButton
        className={clsx('controlsPlayButton prev', { disabled: disablePrev })}
        onClick={onPrevClick}
      />
      <ArrowVideoButton
        className={clsx('controlsPlayButton next', { disabled: disableNext })}
        onClick={onNextClick}
      />
      <Volume videoRef={videoRef} />
      {inFullscreen ? (
        <FullscreenExitButton
          width={25}
          height={25}
          className="controlsPlayButton"
          onClick={onFullscreenClick}
        />
      ) : (
        <FullscreenButton
          width={25}
          height={25}
          className="controlsPlayButton"
          onClick={onFullscreenClick}
        />
      )}
    </div>
  </div>
);

export default Controls;
