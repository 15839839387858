import React from 'react';

import ErrorStore from '@/modules/error/ErrorStore';

import AdminStore from '../modules/admin/AdminStore';
import AuthStore from '../modules/auth/AuthStore';
import MainStore from '../modules/main/MainStore';
import TestStore from '../modules/test/TestStore';
import UserStore from '../modules/user/UserStore';
import VideoStore from '../modules/video/VideoStore';

class RootStore {
  adminStore: AdminStore;

  authStore: AuthStore;

  userStore: UserStore;

  mainStore: MainStore;

  videoStore: VideoStore;

  testStore: TestStore;

  errorStore: ErrorStore;

  constructor() {
    this.errorStore = new ErrorStore();
    this.adminStore = new AdminStore();
    this.authStore = new AuthStore();
    this.userStore = new UserStore();
    this.mainStore = new MainStore();
    this.videoStore = new VideoStore();
    this.testStore = new TestStore(this.errorStore);
  }
}

export const rootStore = new RootStore();
export const storesContext = React.createContext(rootStore);
