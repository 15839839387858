import { Form } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';

import { useRootStore } from '@/base/hooks/useRootStore';
import QButton from '@/components/QButton';
import QInput, { QInputMask } from '@/components/QInput';
import { ILoginForm } from '@/modules/auth/ILoginForm';
import { PhoneFormState } from '@/modules/auth/forms/PhoneForm';

import { TEXT_CONST } from '../AuthScreen.types';
import ResendButton from './ResendButton';

const PhoneForm = observer(() => {
  const { authStore } = useRootStore();
  const [loginData, setLoginData] = useState<ILoginForm>(PhoneFormState);

  // Handlers
  const handleGetCode = () => {
    authStore.getCode(loginData.phone);
  };
  const handleSubmit = () => {
    authStore.login(loginData);
  };

  const handleBack = () => {
    setLoginData(PhoneFormState);
    authStore.resetStore();
  };

  return (
    <>
      <Form>
        {authStore.isCodeReceived ? (
          <QInput
            data-test-id="auth-code-input"
            name="code"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setLoginData({
              ...loginData,
              code: e.target.value,
            })
            }
            value={loginData.code}
            placeholder={TEXT_CONST.CODE}
            maxLength={4}
            error={Boolean(authStore.errorMessage.length)}
            message={authStore.errorMessage}
            onPressEnter={handleSubmit}
          />
        ) : (
          <QInputMask
            data-test-id="auth-phone-input"
            value={loginData.phone}
            error={Boolean(authStore.errorMessage.length)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setLoginData({ ...loginData, phone: e.target.value.replace(/[^a-zA-Z0-9]/g, '') });
            }}
            onPressEnter={handleGetCode}
            message={authStore.errorMessage}
            mask="+7 (000) 000-00-00"
          />
        )}
      </Form>
      {authStore.isCodeReceived ? (
        <QButton
          data-test-id="auth-enter-button"
          disabled={!loginData.code}
          onClick={handleSubmit}
          loading={authStore.loading}
        >
          {TEXT_CONST.ENTER}
        </QButton>
      ) : (
        <QButton
          data-test-id="auth-next-button"
          disabled={loginData.phone.length < 2}
          onClick={handleGetCode}
          loading={authStore.loading}
        >
          {TEXT_CONST.NEXT}
        </QButton>
      )}
      {authStore.isCodeReceived && (
        <>
          <ResendButton
            data-test-id="auth-resend-button"
            sentTime={authStore.codeTimeSent}
            expireTime={authStore.newCodeTime}
            onClick={handleGetCode}
          />
          <QButton className="backButton" data-test-id="auth-back-button" type="default" onClick={handleBack}>
            {TEXT_CONST.BACK}
          </QButton>
        </>
      )}
    </>
  );
});

export default PhoneForm;
