import { AxiosResponse } from 'axios';

import $api from '@/base/api';

import { Course, CourseInfo } from '../common/models/Course';
import {
  AdminCourses,
  AdminTestsResponse,
  CreateCourse,
  CreateCourseResponse,
  Statistic,
  TestsResultsResponse,
  UploadFileForm,
  UserInfoResponse,
} from './AdminInterface';

export default class AdminService {
  createCourse = async (values: CreateCourse): Promise<AxiosResponse<CreateCourseResponse>> =>
    $api.post('/api/desktop/v1/admin/course', values);

  uploadFile = async (courseId: number, values: UploadFileForm) =>
    $api.post(`/api/desktop/v1/course/${courseId}/video`, values, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      timeout: 10800000,
    });

  getCourses = async (isActive?: string): Promise<AxiosResponse<AdminCourses[]>> => {
    let queryParams = '';
    const url = '/api/desktop/v1/admin/course';

    if (isActive === '0' || isActive === '1') {
      queryParams = `?is_active=${isActive}`;
    }
    return $api.get(url + queryParams);
  };

  getTests = async (
    tabId?: string,
    pageNumber = 1,
    pageSize = 20,
  ): Promise<AxiosResponse<AdminTestsResponse>> => {
    let queryParams = `?page=${pageNumber}&limit=${pageSize}`;
    const url = '/api/desktop/v1/admin/attempt';

    switch (tabId) {
      case '0':
        queryParams += '&statuses[0]=passed&statuses[1]=failed';
        break;
      case '1':
        queryParams += '&statuses[0]=passed';
        break;
      case '2':
        queryParams += '&statuses[0]=failed';
        break;
      default:
        queryParams += '&statuses[0]=passed&statuses[1]=failed';
    }
    return $api.get(url + queryParams);
  };

  getTestsResults = async (searchValue: string): Promise<AxiosResponse<TestsResultsResponse[]>> =>
    $api.get(`/api/desktop/v1/admin/attempt/users?search=${searchValue}`);

  getUserTestsResults = async (userId: number): Promise<AxiosResponse<UserInfoResponse>> =>
    $api.get(`/api/desktop/v1/admin/attempt/users/${userId}`);

  submitCourse = (courseId: number, formData: FormData) =>
    $api.post(`/api/desktop/v1/admin/course/${courseId}/question/multiple`, formData);

  setCourseActive = (courseId: number, isActive: boolean) =>
    $api.put(`/api/desktop/v1/admin/course/${courseId}`, {
      is_active: isActive,
    });

  changeCourseInfo = async (courseId: number, values: CourseInfo) =>
    $api.put(`/api/desktop/v1/admin/course/${courseId}`, values);

  changeVideoTitle = async (courseId: number, videoId: number, name: string) =>
    $api.post(`/api/desktop/v1/course/${courseId}/video/${videoId}`, { name });

  getVideos = async (courseId: number): Promise<AxiosResponse<Course>> =>
    $api.get(`/api/desktop/v1/admin/course/${courseId}`);

  deleteVideo = async (courseId: number, videoId: number) =>
    $api.delete(`/api/desktop/v1/course/${courseId}/video/${videoId}`);

  deleteCourse = async (courseId: number) =>
    $api.delete(`/api/desktop/v1/admin/course/${courseId}`);

  getStatistic = async (): Promise<AxiosResponse<Statistic>> => $api.get('/api/desktop/v1/admin');

  passTest = async (courseId: number, answers: number[]) =>
    $api.post(`/api/desktop/v1/admin/course/${courseId}/attempt`, { answers });
}
