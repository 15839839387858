import './styles.css';
import clsx from 'clsx';
import { PropsWithChildren } from 'react';

interface QCardProps {
  width?: number;
  height?: number;
  className?: string;
}

const QCard = ({ children, width, height, className }: PropsWithChildren<QCardProps>) => (
  <div className={clsx(className, 'qCard')} style={{ width: `${width}px`, height: `${height}px` }}>
    {children}
  </div>
);
export default QCard;
