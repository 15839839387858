import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { ReactComponent as CloseIcon } from 'assets/close.svg';
import { useState } from 'react';
import ReactPlayer from 'react-player';
import { useNavigate, useParams } from 'react-router-dom';

import './styles.less';

const antIcon = <LoadingOutlined style={{ fontSize: 100 }} spin />;

const FinishedVideoScreen = () => {
  const { courseId, videoId } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  return (
    <div className="finishedVideo">
      <ReactPlayer
        config={{ file: { attributes: { controlsList: 'nodownload' } } }}
        url={`/api/desktop/v1/course/${courseId}/video/${videoId}`}
        controls={!isLoading}
        width="100vw"
        height="100vh"
        onReady={() => setIsLoading(false)}
        style={{
          background: isLoading
            ? `no-repeat center/50%  url('/api/desktop/v1/course/${courseId}/preview/${videoId}')`
            : '',
        }}
      />
      <CloseIcon className="finishedVideoClose" onClick={() => navigate('/finished')} />
      {isLoading && <Spin indicator={antIcon} className="finishedVideoSpin" />}
    </div>
  );
};

export default FinishedVideoScreen;
