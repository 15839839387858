import axios from 'axios';

import { rootStore } from '../RootStore';

const $api = axios.create({
  withCredentials: true,
  baseURL: process.env.REACT_APP_API_URL,
});

$api.interceptors.request.use((config) => {
  config.headers.token = localStorage.getItem('token');

  return config;
});

$api.interceptors.response.use(
  (config) => config,
  (error) => {
    if (error.response.status === 401) {
      rootStore.authStore.logout();
    }
    return Promise.reject(error);
  },
);

export default $api;
