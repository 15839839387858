import { Modal, Radio, Typography } from 'antd';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import AdminButton from '@/admin/components/AdminButton';
import { useRootStore } from '@/base/hooks/useRootStore';

import './styles.less';

const TEXT_CONST = {
  title: 'Курс будет доступен:',
  admin: 'Только администраторам',
  all: 'Всем пользователям',
  cancel: 'Отменить',
  submit: 'Создать курс',
  default: 'По умолчанию курс виден только администраторам',
};

const VisibilityPopup = observer(() => {
  const { adminStore } = useRootStore();
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleSubmit = async () => {
    await adminStore.setCourseActive(isVisible).then(() => adminStore.setIsPopupOpened(false));
    await adminStore.resetStore();
    await navigate('/admin/courses');
  };

  const Footer = () => (
    <div className="visibilityPopupBtnWrapper">
      <AdminButton width={113} height={40} onClick={() => adminStore.setIsPopupOpened(false)}>
        {TEXT_CONST.cancel}
      </AdminButton>
      <AdminButton width={137} height={40} type="primary" onClick={handleSubmit}>
        {TEXT_CONST.submit}
      </AdminButton>
    </div>
  );

  return (
    <Modal
      open={adminStore.isPopupOpened}
      centered
      width={384}
      footer={<Footer />}
      className="visibilityModal"
    >
      <div className="visibilityPopup">
        <Typography className="visibilityPopupTitle">{TEXT_CONST.title}</Typography>
        <Radio.Group value={isVisible} onChange={(e) => setIsVisible(e.target.value)}>
          <Radio value={false}>{TEXT_CONST.admin}</Radio>
          <Radio value={true}>{TEXT_CONST.all}</Radio>
        </Radio.Group>
        <Typography className="visibilityPopupSpan">{TEXT_CONST.default}</Typography>
      </div>
    </Modal>
  );
});

export default VisibilityPopup;
