import { Suspense } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import QLoader from '@/components/QLoader';

import Header from '../Header/Header';

const Layout = () => {
  const { pathname } = useLocation();
  const isPrivateRoute = pathname === '/auth';

  return (
    <>
      {!isPrivateRoute && <Header />}
      <Suspense fallback={<QLoader spinning />}>
        <Outlet />
      </Suspense>
    </>
  );
};

export default Layout;
